import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decryptPassword, formatCurrency, formatDate, formatDateHypen, renderAddress } from "../../utils/Encryption";
import Gifloader from "../layout/gifloader";
import { commonError, commonSuccess, getTransfers, resetGetTransfers, resetgetTransferFailed } from "../../actions/customerActions";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const RecentMoneyTransfer = ({ typeis }) => {
  const dispatch = useDispatch();
  let tempToken = sessionStorage.getItem("guid");
  let deToken = decryptPassword(tempToken);
  const navigate = useNavigate();

  const getExternalBankList = useSelector((state) => state.customerReducer.getExternalBankAccountListData);
  const getExternalBankAccountWalletList = useSelector((state) => state.customerReducer.getExternalBankWalletAccountData);
  const getTransactionData = useSelector((state) => state.customerReducer.getTransferData);
  const getTransactionFailed = useSelector((state) => state.customerReducer.getTransferFailed);

  const [transList, setTransList] = useState([]);
  const [isloader, setIsLoader] = useState(true);
  const [externalBankList, setExternalBankList] = useState({});
  const [walletList, setWalletList] = useState({});
  const [transactionData, setTransactionData] = useState({});
  const [pageNum, setPageNum] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [copied, setCopied] = useState(false);


  useEffect(() => {
    dispatch(getTransfers(sessionStorage.at, deToken))
  }, []);

  useEffect(() => {
    if (Object.keys(getExternalBankList).length !== 0) {
      setExternalBankList(getExternalBankList);
    }
  }, [getExternalBankList]);

  useEffect(() => {
    if (Object.keys(getExternalBankAccountWalletList).length !== 0) {
      setWalletList(getExternalBankAccountWalletList);
    }
  }, [getExternalBankAccountWalletList]);

  useEffect(() => {
    if (Object.keys(getTransactionData).length !== 0) {
      setTransactionData(getTransactionData);
    }
  }, [getTransactionData]);

  useEffect(() => {
    // Check if data from three API calls is available.
    if (
      Object.keys(externalBankList).length !== 0 &&
      Object.keys(walletList).length !== 0 &&
      Object.keys(transactionData).length !== 0
    ) {
      ;
      // Initialize temporary variables to store data.
      let tempTransaction;
      let tempBank = externalBankList.objects;
      let tempWallet = walletList.objects;

      // Check if a filter type (`typeis`) is specified.
      if (typeis === null) {
        tempTransaction = transactionData.objects;
      } else {
        // If a filter type is specified, get transactions matching the type withdraw or deposit.
        tempTransaction = transactionData.objects?.filter(item => item.side === typeis);
      }

      // Process each transaction to add bank and wallet information.
      const transactionsWithInfo = tempTransaction.map((transaction, i) => {
        const matchingBank = tempBank?.find((bank) => {
          return bank.guid === transaction.external_bank_account_guid;
        });

        const matchingWallet = tempWallet?.find((wallet) => {
          return wallet.guid === transaction?.destination_account?.guid;
        });

        return {
          ...transaction,
          // Create a formatted bank info string if a matching bank is found.
          bankInfo: matchingBank
            ? matchingBank.plaid_institution_id +
            " - " +
            matchingBank.plaid_account_name +
            " (" +
            matchingBank.plaid_account_mask +
            ")"
            : "",
          // Use wallet name if a matching wallet is found.
          walletInfo: matchingWallet ? matchingWallet.name : "",
        };
      });

      setTransList(transactionsWithInfo);
      setIsLoader(false);
      dispatch(resetGetTransfers());
    }
  }, [externalBankList, walletList, transactionData]);


  // If transaction data failed
  useEffect(() => {
    if (Object.keys(getTransactionFailed).length !== 0) {
      let errObj = getTransactionFailed;
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message));
        dispatch(resetgetTransferFailed());
        if (
          errObj.data.error_message == "Signature has expired" ||
          errObj.data.message_code == "authentication_failed"
        ) {
          navigate("/logout");
        }
      }
    }
  }, [getTransactionFailed]);


  const copyaddress = (guid) => {
    const textArea = document.createElement('textarea'); // Create a new <textarea> element in the DOM.
    textArea.value = guid; // Set the value of the <textarea> to the provided 'guid'.
    document.body.appendChild(textArea); // Append the <textarea> element to the document's body.
    textArea.select(); // Select the content within the <textarea>.
    document.execCommand('copy'); // Execute the 'copy' command to copy the selected text to the clipboard.
    document.body.removeChild(textArea); // Remove the temporary <textarea> element from the document's body.
    dispatch(commonSuccess('Text copied to clipboard'));
    setCopied(true);

    // Reset the "Copied" text back to "Copy" after a delay (e.g., 2 seconds).
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }
  
  // Calculate the value based on asset
  const divideByAsset = (asset) => {
    if (asset === "USD") return 100;
    else if (asset === "BTC") return 100000000;
    else if (asset === "ETH") return 1000000000000000000;
    else if (asset === "USDC") return 1000000;
  }

  // Pagination calculations
  const itemsPerPage = 10;
  let pageCount = Math.ceil(transList.length / itemsPerPage);
  const endOffset = pageNum + itemsPerPage;
  let currentItems = transList.slice(pageNum, endOffset);

  // Changing the page number
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % transList.length;
    setPageNum(newOffset);
    setCurrentPage(event.selected);
  }
  const setStatusClassName = (state) => {
    if (state === "completed") {
        return "mw-badge badge badge-success"
      } else if (state === "cancelled" || state === "failed") {
        return "mw-badge badge badge-danger1"
      } else {
        return "mw-badge badge badge-warning"
      }
  }

  
  const Items = ({ currentItems }) => {
    return (
      <>
        <div className="card-body py-0">
          <>
            <div className="transaction-table">
              <div className="table-responsive">
                <table className="table mb-0 table-responsive-sm">
                  <thead>
                    <tr>
                      <th className="text-start">GUID</th>
                      <th className="text-start">Type</th>
                      <th className="text-start">Description</th>
                      <th className="text-start">Amount</th>
                      <th className="text-start">Fee</th>
                      <th className="text-start">Status</th>
                      <th className="text-start">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length == 0 ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          <p>No transactions found.</p>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {currentItems.map((item) => (
                          <tr key={item.guid}>
                            <td>
                              <OverlayTrigger overlay={<Tooltip>{item.guid}</Tooltip>}>
                                <span>
                                  {item.guid}
                                </span>
                              </OverlayTrigger>

                              <OverlayTrigger overlay={<Tooltip> {copied ? "Copied" : "Copy"}</Tooltip>}>
                                <span className="text-primary px-2" type="button" id="button-addon2" onClick={() => copyaddress(item.guid)}>
                                  <i className="mdi mdi-content-copy"></i>
                                </span>
                              </OverlayTrigger>
                            </td>
                            <td>
                              {item.side === "deposit" ? (
                                <>
                                  <span className="mw-badge badge badge-danger">
                                    {item.side}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="mw-badge badge badge-primary">
                                    {item.side}
                                  </span>
                                </>
                              )}
                            </td>
                            
                            <td>{item.transfer_type === "funding" ? item.bankInfo : 
                              <>
                                <OverlayTrigger overlay={<Tooltip>{item.txn_hash}</Tooltip>}>
                                  <span>
                                    {`Txn_hash - ${renderAddress(item.txn_hash)}`}
                                  </span>
                                </OverlayTrigger>

                                <OverlayTrigger overlay={<Tooltip> {copied ? "Copied" : "Copy"}</Tooltip>}>
                                  <span className="text-primary px-2" type="button" id="button-addon2" onClick={() => copyaddress(item.txn_hash || item.bankInfo)}>
                                    <i className="mdi mdi-content-copy"></i>
                                  </span>
                                </OverlayTrigger>
                              </> }

                              <a href={item.asset === "BTC" ? `https://www.blockchain.com/explorer/transactions/btc/${item.txn_hash}` : 
                                item.asset === "ETH" ? `https://etherscan.io/tx/${item.txn_hash}` : 
                                item.asset === "USDC" ? `https://cronoscan.com/tx/${item.txn_hash}` : "#"} 
                                className={`text-primary fw-bold ${item.asset === "USD" ? "d-none" : ""}`} target="_blank">
                                  <i class="mdi mdi-link-variant" aria-hidden="true"></i>
                              </a>                           
                            </td>

                            <td className="text-start">{item.asset === "USD" ? (<>$<span className="text-primary fw-bold">{formatCurrency(item.estimated_amount / divideByAsset(item.asset))?.slice(1).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}</span> {item.asset}</>) : (<><span className="text-primary fw-bold">{item.asset === "USDC" ? (item.estimated_amount / divideByAsset(item.asset))?.toFixed(4) : (item.estimated_amount / divideByAsset(item.asset))?.toFixed(8)}</span> {item.asset}</>)}</td>
                            <td className="text-start"> {item.asset === "USD" && "$"}<span className="text-primary fw-bold">{(item.fee / divideByAsset(item.asset))}</span> {item.asset}</td>
                            <td className="text-center txn-table-stat">
                                <span className={setStatusClassName(item.state)}>{item.state}</span> <br />
                                <span className={`${setStatusClassName(item.state)} mt-1 badge-max`}>{item.failure_code !== null && item.failure_code}</span></td>
                            <td className="text-start">{formatDateHypen(item.created_at)}</td>
                          </tr>
                        ))}
                      </>
                    )}

                  </tbody>
                </table>
              </div>
            </div>
          </>
        </div>
      </>
    )
  }


  return (
    <>
      {isloader ? (
        <>
          <div className=" justify-content-center d-flex align-item-center my-4">
            <Gifloader />
          </div>
        </>
      ) : (
        <>
          <div>
            <Items currentItems={currentItems} />
            <div className={isloader || currentItems.length === 0 ? "d-none" : "d-block"}>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="Previous"
                containerClassName={
                  "pagination justify-content-center my-3"
                }
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                forcePage={pageNum === 0 ? 0 : currentPage}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecentMoneyTransfer;
