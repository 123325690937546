import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { Row, Col, Card } from 'react-bootstrap'


function Footer1() {

    const [isActive, setIsActive] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [isActive3, setIsActive3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);


    const toggleClass = () => {
        setIsActive(!isActive);
    }

    const toggleClass2 = () => {
        setIsActive2(!isActive2);
    }
    const toggleClass3 = () => {
        setIsActive3(!isActive3);
    }

    const toggleClass4 = () => {
        setIsActive4(!isActive4);
    }

    return (
        <>
            <div className="contact-form section-padding footer" id="contact">
                <div className="lftbar"></div>
                <div className="rghtbar"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="info-list footinfo">
                                <img src={require('./../../images/logo-with-text.png')} alt="" />
                                {/* <p>Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                                <p>With a decade-long tenure in the dynamic and rapidly evolving field of cryptocurrency, The Crypto Exchange and its team of experts stand as seasoned leaders at the forefront of the digital financial revolution.</p>
                            </div>
                        </div>


                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="info-list">
                                        <h4 className={isActive ? 'dropup mb-3' : 'dropdown mb-3'} onClick={toggleClass}>Quick links</h4>
                                        <ul className={isActive ? 'active-box' : 'inactive-box'}>
                                            <li><Link to={"/exchange"}><a href="exchange">Exchange</a></Link></li>
                                            <li><a href="services">Services</a></li>
                                            <li><a href="https://blog.thecryptoexchange.us/" target="_blank">Blog</a></li>
                                            <li><a href="about">About</a></li>
                                            {/* <li><a href="#">Explore</a></li>
                                    <li><a href="https://calendly.com/thecryptoexchange">Book Free Consulation</a></li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="info-list">
                                        <h4 className={isActive2 ? 'dropup mb-3' : 'dropdown mb-3'} onClick={toggleClass2}>Individuals</h4>
                                        <ul className={isActive2 ? 'active-box' : 'inactive-box'}>
                                            <li><a href="exchange">Buy/Sell</a></li>
                                            <li><a href="deposits">Deposit</a></li>
                                            <li><a href="withdrawals">Withdraw</a></li>
                                            <li><a href="wallet">Wallet</a></li>

                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="info-list">
                                        <h4 className={isActive3 ? 'dropup mb-3' : 'dropdown mb-3'} onClick={toggleClass3}>Support</h4>
                                        <ul className={isActive3 ? 'active-box' : 'inactive-box'}>
                                            <li><a href="learn">Learn</a></li>
                                            {/* <li><a href="faq">FAQ</a></li> */}
                                            <li><a href="terms-and-conditions">Terms Conditions</a></li>
                                            <li><a href="privacy-policy">Privacy Policy</a></li>
                                            <li><a href="contact">Contact Us</a></li>
                                            {/* <li><a href="#">Cookie Policy</a></li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="info-list">
                                        <h4 className={isActive4 ? 'dropup mb-3' : 'dropdown mb-3'} onClick={toggleClass4}>Location</h4>
                                        <ul className={isActive4 ? 'active-box' : 'inactive-box'}>
                                            <li>
                                                1001 S Main Street STE 600, <br />
                                                Kalispell, Montana, <br />
                                                USA 59901
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="sociallinks">
                                <div className='social_medias'>
                                    <a href="#" aria-label="Logo"><i className="mdi mdi-facebook"></i></a>
                                    <a href="https://twitter.com/the_crypto_exch" target='"_blank' aria-label="Logo"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/></svg></a>
                                    <a href="#" aria-label="Logo"><i className="mdi mdi-linkedin"></i></a>
                                    <a href="" aria-label="Logo"><i className="mdi mdi-instagram"></i></a>
                                </div>
                                <div className="copyright">
                                    <p>© 2024 The Crypto Exchange. All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-end d-flex">
                            {/* <hr /> */}
                            <p>Developed @ <a href='https://blaze.ws' target='_blank' className="devby">BLA<span className='text-primary'>Z</span>E</a></p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer1;