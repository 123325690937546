import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";


function Gettingstarted() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');

    useEffect(() => {
        let email = localStorage.getItem("email_verify");
        if(email){
            setEmail(email);
        }else{
            navigate("/signin");
        }
    })

    const handlesubmit = () => {
        localStorage.removeItem("email_verify");
        navigate("/signin");
    }

    return (
        <>
            <div className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-6">
                            <div className="mini-logo text-center my-5">
                                <Link to={"/"}>
                                    <img
                                        src={require("./../../images/logo-with-text.png")}
                                        alt=""
                                    />
                                </Link>
                            </div>
                            <div className="auth-form card verifylinl">
                                <div className="card-body text-center">
                                    <div className="iccir">
                                        <img
                                            src={require("./../../images/verified-1.png")}
                                            alt=""
                                        />
                                    </div>
                                    <h1>Verify your email</h1>
                                    <p>We've sent an email to {email} for email verification and account activation. The link in the email will expire in 24 hours.</p>
                                    <p>After verification, click below button to sign in.</p>
                                    <button className="btn btn-primary" onClick={handlesubmit}>Sign in</button>
                                    {/* <p className="text-primary mt-3"><a href="#">Click here</a> if you did not receive an email</p> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gettingstarted;