import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  signUpUser,
  resetSignUpUser,
  resetSignUpUserFailed
} from "../../actions/authAction.js";
import ButtonLoader from "../layout/buttonLoader";
import { commonError } from "../../actions/customerActions.js";


function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const signUpError = useSelector((state) => state.authReducer.signUpFailed);
  const signUpSuccess = useSelector((state) => state.authReducer.signUpSuccess);


  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobileNo: "",
    customerType: "individual",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  

  useEffect(() => {
    if (signUpError && signUpError.response) {
      // console.log(signUpError.response.data.data);
      dispatch(commonError(signUpError?.response?.data?.message))
      dispatch(resetSignUpUserFailed())
      setIsLoading(false);
    }
  }, [signUpError]);

  useEffect(() => {
    if (Object.keys(signUpSuccess).length !== 0) {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        customerType: "",
      });
      setIsLoading(false);
      dispatch(resetSignUpUser());
      if (signUpSuccess && signUpSuccess.data && signUpSuccess.data.statusCode === 200) {
        // navigate("/signin");
        navigate("/getting-started");
      };
    }
  }, [signUpSuccess]);


  const handleChange = (e) => {
    const maxLength = 60;
    if (e.target.name === "mobileNo") {
      // Use a regular expression to remove non-numeric characters
      const numericValue = e.target.value.replace(/[^0-9]/g, "");
      // Update the state with the numeric value
      if (numericValue.length <= 10) {
        setFormData({ ...formData, [e.target.name]: numericValue });
      }
    } else if (e.target.name === "firstName" || e.target.name === "lastName") {
      // For first name and last name, allow only letters, hyphens, and apostrophes
      const sanitizedValue = e.target.value
        .replace(/[^a-zA-Z\-\' ]/g, "")
        .slice(0, maxLength);
      setFormData({ ...formData, [e.target.name]: sanitizedValue });
    } else {
      // For other fields, remove unwanted characters and apply the character limit
      const sanitizedValue = e.target.value 
        .slice(0, maxLength);
      setFormData({ ...formData, [e.target.name]: sanitizedValue });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    // Perform client-side form validation here
    const validationErrors = {};
    const isTermsChecked = e.target.terms.checked;
    const isUserAgreementChecked = e.target.userAgreement.checked
    // const isPrivacyChecked = e.target.pa.checked; 
    if (
      !formData.email.trim() ||
      !formData.password.trim() ||
      !formData.lastName.trim() ||
      !formData.firstName.trim() ||
      !formData.mobileNo.trim()
    ) {
      dispatch(commonError("Please fill in all fields."));
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      dispatch(commonError("Invalid email format"));
      return; // Return early if there's an email format error
    }
    if (formData.password.trim().length < 8) { // Change this line
      dispatch(commonError("Password must be at least 8 characters long"));
      return; // Return early if there's a password length error
    }
    if (!(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(formData.password))) {
      dispatch(commonError("Password must include at least one capital letter, one number, and one special character"));
      return; // For password it should contain one letter, one number and one special character
    }
    if (formData.password !== confirmPasswordValue) {
      dispatch(commonError("Passwords do not match. Please make sure your password and confirm password are the same"));
      return; // Check if the password and confirm password both are same
    }
    if (formData.mobileNo.trim().length !== 10) {
      dispatch(commonError("Phone number must be vaild"));
      return;
    }
    if (isTermsChecked === false) {
      dispatch(commonError("Privacy Agreement have not been accepted"));
      return;
    }
    if(isUserAgreementChecked === false) {
      dispatch(commonError("User Agreeement have not been accepted"));
      return;
    }
    // console.log(formData);
    // setErrors({})
    setIsLoading(true);
    // If there are no validation errors, dispatch the signup action
    if (Object.keys(validationErrors).length === 0) {
      localStorage.setItem("email_verify", formData.email);
      dispatch(signUpUser(formData));
      console.log(formData, "Form Data")
    }
  };

  return (
    <>
      <div className="authincation section-padding1 fslogin">
      <div className="art-wrapper">
          <img src={require('./../../images/auth-bg.png')} alt="" />
        </div>
        <div className="fscon-wrapper">
          <div className="container-fluid h-100">             
              <div className="mini-logo text-center mb-4">
                <Link to={"/"}>
                  <img
                    src={require("./../../images/logo-with-text.png")}
                    alt=""
                  />
                </Link>
              </div>
              <div className="auth-form card fslogincard mb-0">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Sign up your account</h4>
                </div>
                <div className="card-body">
                  <form
                    method="post"
                    name="myform"
                    className="signup_validate"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="mb-3 md-6">
                        {/* {typeof errors === "string" && errors !== "" && (
                          <div
                            className="alert alert-danger center justify-content-center"
                            role="alert"
                          >
                            <span>{errors}</span>
                          </div>
                        )} */}
                        <label>First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3 md-6">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                    <label>Customer Type</label>
                    <select 
                      className="form-control" 
                      value={formData.customerType}
                      name="customerType" 
                      onChange={handleChange}
                    >
                      <option value="individual">Individual</option>
                      <option value="business">Business</option>
                    </select>
                  </div>
                    <div className="mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>Mobile Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile Number"
                        name="mobileNo"
                        value={formData.mobileNo}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>Password</label>
                      <div className="input-group">
                        <input
                          type={viewPassword ? "text" : "password"}
                          className="form-control"
                          value={formData.password}
                          onChange={handleChange}
                          name="password"
                          autoComplete="off"
                        />
                        <div className="input-group-append" onClick={() => setViewPassword(!viewPassword)}>
                          <span className="input-group-text bg-transparent cursor-pointer">
                            {viewPassword ? <i className="mdi mdi-eye-outline"></i> : <i className="mdi mdi-eye-off-outline"></i>}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label>Confirm Password</label>
                      <div className="input-group">
                        <input
                          type={viewConfirmPassword ? "text" : "password"}
                          className="form-control"
                          value={formData.confirmPassword}
                          onChange={(e) => setConfirmPasswordValue(e.target.value)}
                          name="confirmPassword"
                          autoComplete="off"
                        />
                        <div className="input-group-append" onClick={() => setViewConfirmPassword(!viewConfirmPassword)}>
                          <span className="input-group-text bg-transparent cursor-pointer">
                            {viewConfirmPassword ? <i className="mdi mdi-eye-outline"></i> : <i className="mdi mdi-eye-off-outline"></i>}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="cursor-pointer sign_check mb-0">
                        <input type="checkbox" name="terms" className="me-2" />
                        <span>I here by agree to the <a href="/terms-and-conditions" target="_blank">terms & conditions</a> and <a href="/privacy-policy" target="_blank">privacy agreement</a></span>
                      </label>

                      <label className="cursor-pointer sign_check mb-0">
                        <input type="checkbox" name="userAgreement" className="me-2" />
                        <span>I here by agree to the <a href="/user-agreement" target="_blank">user agreement</a></span>
                      </label>
                    </div>
                    {/* <div className="mb-3">
                      <label className="cursor-pointer mb-0">
                        <input type="checkbox" name="pa" className="me-2" />
                          Agree 
                      </label>
                    </div> */}
                    <div className="text-center mt-4">
                      <button type="submit" className={`btn btn-primary w-100 btn-loader ${isLoading ? "active" : ""}`}>
                        <span>Sign up</span>
                        <ButtonLoader />
                      </button>
                    </div>
                  </form>
                  {/* {signUpError && (
                    <div className="alert alert-danger mt-3" role="alert">
                      {signUpError}
                    </div>
                  )} */}
                  <div className="new-account mt-3">
                    <p>
                      Already have an account?{" "}
                      <Link className="text-primary" to={"/signin"}>
                        Sign in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
    </>
  );
}

export default Signup;
