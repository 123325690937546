import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";
import { useDispatch, useSelector } from "react-redux";
import {
  commonError,
  getBchHistoryData,
  getBtcHistoryData,
  getEthHistoryData,
  getUsdcHistoryData,
  getLtcHistoryData,
  resetBchHistoryData,
  resetBtcHistoryData,
  resetEthHistoryData,
  resetLtcHistoryData,
  resetUsdcHistoryData,
  resetgetMarketPriceFailed,
} from "../../actions/customerActions";
import bitcoinLogo from "../../images/bitcoin.png";
import ethereumLogo from "../../images/ethereum.png";
import UsdcLogo from "../../images/usdc.png";
import LiteLogo from "../../images/LTC.png";
import BchLogo from "../../images/bch.png";
import {
  landingPageLivePrice,
  resetLandingPageLivePrice,
} from "../../actions/authAction";
import TinyChart from "../charts/tinyLine";
import Gifloader from "../layout/gifloader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

function Landing() {
  const dispatch = useDispatch();
  let livePriceInterval;
  const inputBuyRef = useRef(null);
  const inputSellRef = useRef(null);
  const marKetPriceInterval = 30;//30 seconds


  const liveRef = useRef(null);

  const handleClick = () => {
    liveRef.current?.scrollIntoView({ behavior: 'smooth' } - 50);
  };


  const marketLivePrice = useSelector((state) => state.authReducer.landingLivePriceSuccess);
  const livePriceFailed = useSelector((state) => state.authReducer.landingLivePriceFailed);
  const getMarketPriceResFailed = useSelector((state) => state.customerReducer.getMarketPriceFailed);
  const BtcPrices = useSelector((state) => state.customerReducer.getBTCdata);
  const EthPrices = useSelector((state) => state.customerReducer.getETHdata);
  const UsdcPrices = useSelector((state) => state.customerReducer.getUSDCdata);
  // const LtcPrices = useSelector((state) => state.customerReducer.getLTCdata);
  // const BchPrices = useSelector((state) => state.customerReducer.getBCHdata);


  const [BtcData, setBtcData] = useState([]);
  const [EthData, setEthData] = useState([]);
  const [UsdcData, setUsdcData] = useState([]);
  const [LtcData, setLtcData] = useState([]);
  const [BchData, setBchData] = useState([]);
  const [buyInput, setBuyInput] = useState("");
  const [sellInput, setSellInput] = useState("");
  const [buyQuoteMessage, setBuyQuoteMessage] = useState("");
  const [sellQuoteMessage, setSellQuoteMessage] = useState("");
  const [buyDetails, setBuyDetails] = useState([]);
  const [sellDetails, setSellDetails] = useState([]);
  const [buySelectedObj, setBuySelectedObj] = useState(null);
  const [sellSelectedObj, setSellSelectedObj] = useState({});
  const [isBuyCad, setIsBuycad] = useState(true);
  const [isSellCad, setIsSellCad] = useState(true);
  const [marketPrice, setMarketPrice] = useState([]);
  const [livePriceLoader, setLivePriceLoader] = useState(true);
  const [cadBuyInput, setCadBuyInput] = useState("");
  const [cadSellInput, setCadSellInput] = useState("");
  const [buySelectedSymbol, setBuySelectedSymbol] = useState("");
  const [sellSelectedSymbol, setSellSelectedSymbol] = useState("");


  useEffect(() => {
    const dispatchPromises = [
      dispatch(getBtcHistoryData()),
      // dispatch(getEthHistoryData()),
      dispatch(getUsdcHistoryData()),
      // dispatch(getBchHistoryData()),
      // dispatch(getLtcHistoryData()),
    ];
    Promise.all(dispatchPromises)
      .then(() => {
        dispatch(landingPageLivePrice());
        livePriceInterval = setInterval(() => {
          dispatch(landingPageLivePrice());
        }, marKetPriceInterval * 1000);
      })
      .catch((error) => { });
    setBuyQuoteMessage("");
    setSellQuoteMessage("");

    return () => {
      clearInterval(livePriceInterval);
    };
  }, []);

  useEffect(() => {
    if (BtcPrices && BtcPrices?.length !== 0) {
      const Btc15day = BtcPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
        (price) => price
      );
      setBtcData(Btc15day);
      dispatch(resetBtcHistoryData());
    }
  }, [BtcPrices]);

  useEffect(() => {
    if (EthPrices && EthPrices?.length !== 0) {
      const Eth15day = EthPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
        (price) => {
          return price;
        }
      );
      setEthData(Eth15day);
      dispatch(resetEthHistoryData());
    }
  }, [EthPrices]);

  useEffect(() => {    
      if (UsdcPrices && UsdcPrices.length !== 0) {
        const Usdc15day = UsdcPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
          (price) => {
            return price;
          }
        );
        setUsdcData(Usdc15day);
        dispatch(resetUsdcHistoryData());
      }
    }, [UsdcPrices]);

  // useEffect(() => {
  //   if (LtcPrices.length !== 0) {
  //     const Ltc15day = LtcPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
  //       (price) => {
  //         return price;
  //       }
  //     );
  //     setLtcData(Ltc15day);
  //     dispatch(resetLtcHistoryData());
  //   }
  // }, [LtcPrices]);

  // useEffect(() => {
  //   if (BchPrices.length !== 0) {
  //     const Bch15day = BchPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
  //       (price) => {
  //         return price;
  //       }
  //     );
  //     setBchData(Bch15day);
  //     dispatch(resetBchHistoryData());
  //   }
  // }, [BchPrices]);

  
  useEffect(() => {
    if (marketLivePrice && Object.keys(marketLivePrice).length !== 0) {
      // console.log(marketLivePrice, '[MARKET_LIVE_PRICE]');
      const dataArray = Object.values(marketLivePrice);
      const updatedMarketPrice = dataArray.map((item) => {
        let logo;
        let name;
        let symbol;
        let graphData;
        if (item.symbol == "BTC-USD") {
          logo = bitcoinLogo;
          name = "Bitcoin";
          symbol = "BTC";
          graphData = BtcData;
        } else if (item.symbol == "ETH-USD") {
          logo = ethereumLogo;
          name = "Ethereum";
          symbol = "ETH";
          graphData = EthData;
        } else if (item.symbol == "USDC-USD") {
          logo = UsdcLogo;
          name = "USDC";
          symbol = "USDC";
          graphData = UsdcData;
        }        
        // else if (item.symbol == "LTC-USD") {
        //   logo = LiteLogo;
        //   name = "Litecoin";
        //   symbol = "LTC";
        //   graphData = LtcData;
        // } else if (item.symbol == "BCH-USD") {
        //   logo = BchLogo;
        //   name = "Bitcoin Cash";
        //   symbol = "BCH";
        //   graphData = BchData;
        // }

        return {
          ...item,
          logo: logo,
          name: name,
          symbol: symbol,
          graphData: graphData,
        };
      });

      const buyData = updatedMarketPrice.map((item) => {
        return {
          symbol: item.symbol,
          buy_price: item.buy_price,
          logo: item.logo,
          name: item.name,
        };
      });

      const sellData = updatedMarketPrice.map((item) => {
        return {
          symbol: item.symbol,
          sell_price: item.sell_price,
          logo: item.logo,
          name: item.name,
        };
      });
      setBuyDetails(buyData);
      setSellDetails(sellData);

      if (!buySelectedObj) {
        setBuySelectedObj(buyData[0]);
        setSellSelectedObj(sellData[0]);
      }
      setLivePriceLoader(false);
      setMarketPrice(updatedMarketPrice);
      dispatch(resetLandingPageLivePrice());
    }
  }, [marketLivePrice]);

  useEffect(() => {
    if (Object.keys(getMarketPriceResFailed).length !== 0) {
      let errObj = getMarketPriceResFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetgetMarketPriceFailed())
      }
    }
  }, [getMarketPriceResFailed])


  const handleCurrencyChange = (event, type) => {
    const selectedSymbol = event.target.value;
    if (type === "buy") {
      const currObj = buyDetails.find(
        (currency) => currency.symbol === selectedSymbol
      );
      if (buyInput || cadBuyInput) {
        let amount = ((cadBuyInput * 100) / currObj.buy_price).toFixed(8);
        let quantity = ((buyInput * currObj.buy_price) / 100).toFixed(2);

        setBuySelectedObj({
          ...currObj,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });
      } else {
        setBuySelectedObj(currObj);
      }
      setBuySelectedSymbol(selectedSymbol);
    } else if (type === "sell") {
      const currObj = sellDetails.find(
        (currency) => currency.symbol === selectedSymbol
      );

      if (sellInput || cadSellInput) {
        let amount = ((cadSellInput * 100) / currObj.sell_price).toFixed(8);
        let quantity = ((sellInput * currObj.sell_price) / 100).toFixed(2);
        setSellSelectedObj({
          ...currObj,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });
      } else {
        setSellSelectedObj(currObj);
      }
      setSellSelectedSymbol(selectedSymbol);
    }
  };

  const handleUsdAmountBlur = (e, type) => {
    const inputValue = e.target.value;
    if (type === "buy" && e.target.value) {
      const formattedValue = parseFloat(inputValue).toFixed(2);
      setCadBuyInput(formattedValue);
    } else if (type === "sell" && e.target.value) {
      const formattedValue = parseFloat(inputValue).toFixed(2);
      setCadSellInput(formattedValue);
    } else if (type === "buy" && !e.target.value) {
      setCadBuyInput("0.00");
      handleCadAmountChange("0.00", "buy");
    } else if (type === "sell" && !e.target.value) {
      setCadSellInput("0.00");
      handleCadAmountChange("0.00", "sell");
    }
  };

  const handleCadAmountChange = (inputValue, type) => {
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      return;
    }
    if (type === "buy") {
      setBuySelectedSymbol(buySelectedObj.symbol)
      setIsBuycad(true);
      setCadBuyInput(inputValue);
      let amount = inputValue ? ((inputValue * 100) / buySelectedObj.buy_price).toFixed(8) : 0;
      setBuySelectedObj({
        ...buySelectedObj,
        currentPriceforQuantity: amount,
      });
    } else if (type === "sell") {
      setIsSellCad(true);
      setCadSellInput(inputValue)
      let amount = inputValue ? ((inputValue * 100) / sellSelectedObj.sell_price).toFixed(8) : 0;
      setSellSelectedObj({
        ...sellSelectedObj,
        currentPriceforQuantity: amount,
      });
    }
  }

  const handleUsdAmountChange = (inputValue, type) => {
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      // Invalid input, handle error or ignore the input
      return;
    }
    if (type === "buy") {
      setIsBuycad(false);
      let quantity = inputValue ? ((inputValue * buySelectedObj.buy_price) / 100).toFixed(2) : 0;
      setBuySelectedObj((prevBuySelectedObj) => ({
        ...prevBuySelectedObj,
        currentQuantityforPrice: quantity,
      }));
      setBuyInput(inputValue);
      setBuySelectedSymbol(buySelectedObj.symbol);
    } else if (type === "sell") {
      setIsSellCad(false);
      let quantity = inputValue ? ((inputValue * sellSelectedObj.sell_price) / 100).toFixed(2) : 0;
      setSellSelectedObj((prevSellSelectedObj) => ({
        ...prevSellSelectedObj,
        currentQuantityforPrice: quantity,
      }));
      setSellInput(inputValue);
      setSellSelectedSymbol(sellSelectedObj.symbol);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const accessToken = sessionStorage.getItem("at"); 
  return (
    <>
      {/* Meta Tag */}
      <Helmet>
        <title>The Crypto Exchange | Best Online Crypto Wallet | United States</title>
        <meta name="description" content="Discover safe, easy-to-use crypto wallet app at The Crypto Exchange. We are an online platform where you can securely buy, sell & store your cryptocurrencies." />
      </Helmet>

      <Header3 />

      <div
        className="intro section-padding position-relative bg-pattern"
        id="intro"
      >
        <div className="tce-banner-logo">
          <img src={require("../../images/TCE-logo-banner.png")} alt="" />
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-7 col-md-6">
              <div className="intro-content">
                <h2>
                  <h3 style={{color: '#fff'}}>The Crypto Exchange</h3>
                  Your One-Stop Crypto Trading Platform 
                  <span>Securely buy, sell and store cryptocurrency with “The Crypto Exchange” Our wallet supports all credible cryptocurrencies for trading and conversion into CAD & USD!</span>
                </h2>
                <h4>United States Most Secure and Trusted Cryptocurrency Exchange!</h4>
                <div className="mt-4 mb-5 introbtn">

                  <Link to={'/signup'} className="btn getstarted">Get Started</Link>
                  <Link to={'https://calendly.com/thecryptoexchange'} target="_blank" className="btn btn-outline-light">Book Free Consultation</Link>

                  <form
                    method="post"
                    name="myform"
                    className="currency_validate"
                  >

                    <div className="mailgetquote">
                      {/* <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                          aria-label="Email Address"
                          aria-describedby="button-addon2"
                        />
                        <button
                          className="btn btn-dark"
                          type="button"
                          id="button-addon2"
                        >
                          Get Started
                        </button>
                      </div> */}

                    </div>
                  </form>
                </div>

                <div className="introdown">
                  <h4 className="introdowntit text-center mb-3">
                    {/* Trade 100's of Additional Crypto Currencies On Your DEFI Wallet App */}
                    Download the most secure and advanced crypto wallet
                  </h4>
                  <div className="download_sec pt-0">
                    {/* <h4>Download</h4> */}

                    <div className="gastore">
                      {/* <a href="http://www.thecryptowallet.io/" target="_blank" title="Google Play store" rel="noreferrer">
                        <img
                          src={require("../../images/google_button.png")}
                          alt=""
                          width="400"
                          className="img-fluid"
                        />
                      </a> */}
                      <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store" rel="noreferrer">
                        <img
                          src={require("../../images/apple_button.png")}
                          alt=""
                          width="400"
                          className="img-fluid"
                        />
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            {/* EXCHANGE COMPONENT  */}
            <div className="col-xl-5 col-md-6 exchange_sec">
              <h2 className="display_mob">Your One-Stop Crypto Trading Platform</h2>
              <div className="card fadeInDown">
                <div className="card-body p-0">
                  <div className="buy-sell-widget landingbsw exchangetabs">
                    <Tabs
                      defaultActiveKey="buy"
                      id="uncontrolled-tab-example"
                      className="m-0"
                    >
                      <Tab eventKey="buy" title="Buy">
                        <form
                          method="post"
                          name="myform"
                          className="currency_validate fadeInDown"
                        >
                          <div className={"row"}>
                            <div className="col-sm-12">
                              <div className="mb-3">
                                <label>You Pay</label>
                                <div className="input-group mb-3 igpcust">
                                  <div className="input-group-prepend selpoabs">
                                    <label className="input-group-text" id="ste1">
                                      <img
                                        src={require("../../images/usd.png")}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </label>
                                    <select disabled aria-label="ste1"
                                      name="currency"
                                      className="form-control smallselect bg-img-none">
                                      <option>{"USD"}</option>
                                    </select>
                                  </div>

                                  <div className="dollor d-flex justify-content-start align-items-center px-0 ps-md-2 fw-bold fs-3 dollorclr">$</div>

                                  <div className="pays">

                                    <input id="youpay"
                                      type="text"
                                      name="buy_cad_amount"
                                      className="form-control ps-1"
                                      onChange={(e) => handleCadAmountChange(e.target.value, "buy")}
                                      onBlur={(e) => handleUsdAmountBlur(e, "buy")}
                                      value={isBuyCad ? cadBuyInput : buySelectedObj && buySelectedObj.currentQuantityforPrice || ""}
                                      onKeyPress={(e) => {
                                        const key = e.key;
                                        if (key !== "." && isNaN(Number(key))) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <div className="mb-3">
                                <label for="youget">You Get</label>
                                <div className="input-group mb-3 igpcust">
                                  <div className="input-group-prepend selpoabs">
                                    <label className="input-group-text"  id="curcy1">
                                      <img
                                        src={buySelectedObj && buySelectedObj.logo}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </label>
                                    <select aria-label="curcy1"
                                      name="currency"
                                      className="form-control smallselect"
                                      onChange={(e) => {
                                        handleCurrencyChange(e, "buy")
                                        inputBuyRef.current.focus();
                                      }}
                                      value={buySelectedObj && buySelectedObj.symbol}
                                    >
                                      {buyDetails?.map((currency) => (
                                        <option
                                          key={currency.symbol}
                                          value={currency.symbol}
                                          selected
                                        >
                                          {currency.symbol}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="pays">

                                    <input id="youget"
                                      type="text"
                                      name="usd_amount"
                                      className="form-control"
                                      onChange={(e) => handleUsdAmountChange(e.target.value, "buy")}
                                      value={!isBuyCad ? buyInput : buySelectedObj && buySelectedObj.currentPriceforQuantity || ""}
                                      ref={inputBuyRef}
                                      onKeyPress={(e) => {
                                        const key = e.key;
                                        if (key !== "." && isNaN(Number(key))) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 mx-auto">
                                {!accessToken ?
                                  <a href="/signup"
                                    className="btn btn-primary w-100 mt-3"
                                  >
                                    Sign up
                                  </a>
                                  :
                                  <a href="/exchange"
                                    className="btn btn-primary w-100 mt-3"
                                  >
                                    Exchange
                                  </a>
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </Tab>
                      <Tab eventKey="sell" title="Sell" >
                        <form
                          method="post"
                          name="myform"
                          className="currency_validate fadeInDown"
                        >
                          <div className={"row"}>
                            <div className="col-sm-12">
                              <div className="mb-3">
                                <label>You Pay</label>
                                <div className="input-group mb-3 igpcust">
                                  <div className="input-group-prepend selpoabs">
                                    <label className="input-group-text" id="crtcy2">
                                      <img
                                        src={sellSelectedObj && sellSelectedObj.logo}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </label>
                                    <select aria-label="crtcy2"
                                      name="currency"
                                      className="form-control smallselect"
                                      onChange={(e) => {
                                        handleCurrencyChange(e, "sell")
                                        inputSellRef.current.focus();
                                      }}
                                      value={sellSelectedObj && sellSelectedObj.symbol}
                                    >
                                      {sellDetails?.map((currency) => (
                                        <option
                                          key={currency.symbol}
                                          value={currency.symbol}
                                        >
                                          {currency.symbol}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="pays">

                                    <input
                                      type="text"
                                      name="usd_amount"
                                      className="form-control"
                                      onChange={(e) => handleUsdAmountChange(e.target.value, "sell")}
                                      value={!isSellCad ? sellInput : sellSelectedObj && sellSelectedObj.currentPriceforQuantity || ""}
                                      ref={inputSellRef}
                                      onKeyPress={(e) => {
                                        const key = e.key;
                                        if (key !== "." && isNaN(Number(key))) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="mb-3">
                                <label>You Get</label>
                                <div className="input-group mb-3 igpcust">
                                  <div className="input-group-prepend selpoabs">
                                    <label className="input-group-text" id="ste2">
                                      <img
                                        src={require("../../images/usd.png")}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </label>
                                    {/* <label className="input-group-text small">
                                        {"USD"}
                                      </label> */}
                                    <select disabled aria-label="ste2"
                                      name="currency"
                                      className="form-control smallselect bg-img-none">
                                      <option>{"USD"}</option>
                                    </select>
                                  </div>
                                  <div className="dollor d-flex justify-content-start align-items-center px-0 ps-md-2 fw-bold fs-3 dollorclr">$</div>
                                  <div className="pays">

                                    <input
                                      type="text"
                                      name="sell_cad_amount"
                                      className="form-control ps-1"
                                      onChange={(e) => handleCadAmountChange(e.target.value, "sell")}
                                      onBlur={(e) => handleUsdAmountBlur(e, "sell")}
                                      value={isSellCad ? cadSellInput : sellSelectedObj && sellSelectedObj.currentQuantityforPrice || ""}
                                      onKeyPress={(e) => {
                                        const key = e.key;
                                        if (key !== "." && isNaN(Number(key))) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>


                                </div>
                                <div className="col-12 mx-auto">
                                {!accessToken ?
                                  <a href="/signup"
                                    className="btn btn-primary w-100 mt-3"
                                  >
                                    Sign up
                                  </a>
                                  :
                                  <a href="/exchange"
                                    className="btn btn-primary w-100 mt-3"
                                  >
                                    Exchange
                                  </a>
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </Tab>
                    </Tabs>


                  </div>
                </div>
              </div>
            </div>

            {/* ENDS HERE */}


          </div>
        </div>
        <span className="intro-downarrow cursor-pointer" onClick={handleClick}>
          <i className="mdi mdi-chevron-down"></i>
        </span>
      </div>

      <div className="market section-padding page-section pb-0" id="market" ref={liveRef}>
        <div className="container">
          <div className="row pb-5 justify-content-center">
            <div className="col-xl-7">
              <div className="section_heading mb-0">
                <span>Live Pricing</span>
                {/* <h3>
                                    The World's Leading Crypto Exchange
                                </h3>
                                <p>
                                    Trade Bitcoin, ETH, and hundreds of other
                                    The crypto exchange currencies in minutes.
                                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body pad_0">
                  <div className="market-table">
                    {/* <div className="table-responsive"> */}
                      {livePriceLoader ? (
                        <div>
                          <Gifloader />
                        </div>
                      ) : (
                        <table className="table mb-0 table-responsive-sm">
                          <thead>
                            <tr>
                              <th>Crypto <span>Currencies</span></th>
                              <th className="text-end">
                                Buy Price{" "}
                                <small className="text-primary">(USD)</small>
                              </th>
                              <th className="text-end">
                                Sell Price{" "}
                                <small className="text-primary">(USD)</small>
                              </th>
                              {/* <th className="text-center">Last Updated</th> */}
                              <th className="text-center">Graph</th>
                              <th className="text-center">Exchange</th>
                            </tr>
                          </thead>
                          <tbody>
                            {marketPrice.length > 0
                              ? marketPrice.map((value, key) => {
                                return (
                                   value?.symbol !== "ETH" && (
                                    <tr key={key}>
                                    <td className="coin_icon">
                                      <div className="d_f">
                                        <img
                                          src={value.logo}
                                          alt=""
                                          className="me-2"
                                          width="24"
                                        />
                                        <span>
                                          <bdi>{value.name} </bdi>
                                          <b>{value.symbol}</b>
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      $
                                      {(value.buy_price / 100).toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="text-end">
                                      $
                                      {(
                                        value.sell_price / 100
                                      ).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </td>
                                    {/* <td className="text-center">
                                                            <span className="text-primary">
                                                            {new Date(value.buy_price_last_updated_at).toLocaleString(undefined, {
                                                                month: "short",
                                                                day: "numeric",
                                                                hour: "numeric",
                                                                minute: "numeric",
                                                                hour12: true,
                                                            })}
                                                            </span>
                                                            </td> */}
                                    <td className="text-center py-0 tinychart">
                                      <TinyChart
                                        marketPrices={value.graphData}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <Link
                                        to={"/signin"}
                                        className="btn btn-primary"
                                      >
                                        Buy
                                      </Link>
                                    </td>
                                  </tr>
                                   )
                                );
                              })
                              : ""}
                            {/* <tr>

                                                        <td className="coin_icon">
                                                            <img
                                                                src={require("../../images/bitcoin.png")}
                                                                alt=""
                                                                className="me-2"
                                                                width="24"
                                                            />
                                                            <span>
                                                                Bitcoin{" "}
                                                                <b>BTC</b>
                                                            </span>
                                                        </td>

                                                        <td className="text-end">$38,572</td>
                                                        <td className="text-end">
                                                            <span className="text-primary">
                                                                +0.07%
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            <img src={require("../../images/up-rate.png")} alt="" width='100' />
                                                        </td>
                                                        <td className="text-center">
                                                            <Link
                                                                href="#"
                                                                className="btn btn-primary"
                                                            >
                                                                Buy
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td className="coin_icon">
                                                            <img
                                                                src={require("../../images/ethereum.png")}
                                                                alt=""
                                                                className="me-2"
                                                                width="24"
                                                            />
                                                            <span>
                                                                Ethereum{" "}
                                                                <b>ETH</b>
                                                            </span>
                                                        </td>

                                                        <td className="text-end">$2,457.97</td>
                                                        <td className="text-end">
                                                            <span className="text-primary">
                                                                +0.08%
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            <img src={require("../../images/up-rate.png")} alt="" width='100' />
                                                        </td>
                                                        <td className="text-center">
                                                            <Link
                                                                href="#"
                                                                className="btn btn-primary"
                                                            >
                                                                Buy
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td className="coin_icon">
                                                        <img
                                                                src={require("../../images/LTC.png")}
                                                                alt=""
                                                                className="me-2"
                                                                width="24"
                                                            />
                                                            <span>
                                                            LiteCoin{" "}
                                                                <b>LTC</b>
                                                            </span>
                                                        </td>

                                                        <td className="text-end">$1.33</td>
                                                        <td className="text-end">
                                                            <span className="text-danger">
                                                                -0.22%
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            <img src={require("../../images/down-rate.png")} alt="" width='100' />
                                                        </td>
                                                        <td className="text-center">
                                                            <Link
                                                                href="#"
                                                                className="btn btn-primary"
                                                            >
                                                                Buy
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td className="coin_icon">
                                                        <img
                                                                src={require("../../images/Bitcoin Cash.png")}
                                                                alt=""
                                                                className="me-2"
                                                                width="24"
                                                            />
                                                            <span>
                                                            Bitcoin Cash{" "}
                                                                <b>BCH</b>
                                                            </span>
                                                        </td>

                                                        <td className="text-end">$32.59</td>
                                                        <td className="text-end">
                                                            <span className="text-primary">
                                                                +0.10%
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            <img src={require("../../images/up-rate.png")} alt="" width='100' />
                                                        </td>
                                                        <td className="text-center">
                                                            <Link
                                                                href="#"
                                                                className="btn btn-primary"
                                                            >
                                                                Buy
                                                            </Link>
                                                        </td>
                                                    </tr> */}
                          </tbody>
                        </table>
                      )}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="testimonial section-padding position-relative overflow-hidden bg-pattern"
        id="testimonial"
      >
        <div className="tce-banner-logo">
          <img src={require("../../images/TCE-logo-banner.png")} alt="" />
        </div>
        <div className="container">
          <div
            className="market page-section pb-0 bcimlist-section"
            id="market"
          >
            <div className="container1">
              <div className="card mb-0 overflow-hidden">
                <div className="card-body p-1">
                  <div className="row align-items-center">
                    <div className="col-md-6 text-center">
                      <div className="section_heading text-center mb-0">
                        <h3>Want to trade up to 3000+ <br />crypto currencies</h3>
                        <h3>Download</h3>
                        <div className="appstorelogo mb-3">
                          <div className="dtxtimg">
                            <a href="http://www.thecryptowallet.io/" target="_blank" rel="noopener noreferrer" aria-label="Logo">
                              <img src={require('./../../images/TCW-logo.png')} alt="" className="TCW-logo" />
                            </a>
                          </div>
                        </div>
                        <span>mobile app</span>
                      </div>

                      <div className="download_sec flex-row justify-content-center mt-3">
                      {/* <a href="http://www.thecryptowallet.io/" target="_blank" title="Google Play store" rel="noreferrer">
                        <img
                          src={require("../../images/google_button.png")}
                          alt=""
                          width="400"
                          className="img-fluid"
                        />
                      </a> */}
                      <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store" rel="noreferrer">
                        <img
                          src={require("../../images/apple_button.png")}
                          alt=""
                          width="400"
                          className="img-fluid ms-3"
                        />
                      </a>
                      </div>
                    </div>

                    <div className="col-md-6 _phn_sec">
                      <img
                        src={require("../../images/iphones.png")}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="serv_aprent">
              <h2>Knowing The Cryptocurrency</h2>
              <p>Before your very first transaction with us, we are responsible for bridging your journey from curiosity to investment mastery.</p>
              <p>The cryptocurrency you trade on our platform operates on a decentralized network of computers using blockchain technology to manage and record transactions. This innovation allows for secure, transparent, and peer-to-peer exchanges of value without the need for intermediaries like banks. Understanding the market’s volatility is key to navigating investments wisely. Our platform empowers you with real-time data, secure transactions, and non-custodial freedom. Secure your future of finance with The Crypto Exchange!</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bcimlist-section buy_option mt-5" id="market">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <img
                    src={require("../../images/illustration.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-7">
                  <div className="section_heading">
                    <h3>Crypto Exchange</h3>
                    <span>Buy Crypto in minutes</span>
                  </div>
                  <div className="bcimlist">
                    <p>
                      <span>1</span>Create your free Crypto Exchange account
                    </p>
                    <p>
                      <span>2</span>Connect your funding method
                    </p>
                    <p>
                      <span>3</span>Buy and sell 200+ Crypto currencies
                    </p>
                  </div>

                  <Link to={'/exchange'} className="btn btn-primary">
                    Buy Crypto
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="serv_aprent">
              <h2>New To Crypto, Where to Start?</h2>
              <p>
                Here’s a streamlined guide from the beginning of your exploration into a crypto trading platform:- 
              </p>
              
              <div style={{textAlign: 'left'}}>
                <p>
                  ● <b>Build Your Foundation</b><br />
                  Start by grasping the essence of cryptocurrency, which leverages blockchain technology for decentralized transactions. Familiarise yourself with key concepts like blockchain, digital wallets, and the significance of decentralization in disrupting traditional finance.
                </p>

                <p>
                  ● <b>Register Securely With Us</b><br />
                  With absolute confidence, register with The Crypto Exchange. Our registration process is designed to ensure your security and privacy, requiring identity verification to meet stringent regulatory standards. With us, your venture into cryptocurrencies starts on a foundation of trust and safety.
                </p>

                <p>
                  ● <b>Navigate Crypto Wallet Features</b><br />
                  Understanding and <a href="https://thecryptowallet.io/" style={{color: '#1355d7'}}>choosing the right crypto wallet</a> is crucial for your digital asset management. Our platform offers integrated wallet solutions that provide a highly secure environment with convenient accessibility. Explore various features, such as easy transfers, quick access to your portfolio, and robust protection mechanisms.
                </p>

                <p>
                  ● <b>Begin with Modest Investment</b><br />
                  Stepping into the exchanges for the crypto market doesn’t require a leap; a small first step is all it takes. Starting with a modest investment allows you to familiarize yourself with market fluctuations and trading dynamics without overwhelming risk. It’s an excellent strategy for learning the ins and outs of <a href="https://cryptoexperts.ca/" style={{color: '#1355d7'}}>cryptocurrency investments</a>.
                </p>

                <p>
                  ● <b>Keep Abreast of Developments</b><br />
                  Crypto trading dynamically evolves with new technologies, regulatory updates, and market trends emerging regularly. We provide you with the latest insights and analysis, helping you make well-informed decisions. The habit of continuous learning enhances your investment strategy and optimizes your portfolio’s performance.
                </p>

                <p>
                  ● <b>Strictly Follow Secure Practices</b><br />
                  Vigilance is your best defense against the sophisticated tactics of fraudsters and hackers while using a crypto trading platform. We strongly urge you to cultivate a mindset of security first, beyond just the foundational steps of strong passwords and two-factor authentication. Be wary of phishing attempts that cleverly disguise as legitimate communications, aiming to steal sensitive information. Always verify the authenticity of emails and websites before entering your details. Avoid sharing your private keys or wallet credentials with anyone.
                </p>

                <p className="mb-0">
                  This is all about how your journey begins with a trustworthy United States crypto exchange and our <a href="https://thecryptoexchange.us/services" style={{color: '#1355d7'}}>Crypto Consultant service</a> will guide you in a better way. We equally emphasize learning as we encourage investment. Read our guidance information or visit our Ontario location if you want more personalized assistance. Start with confidence and grow with expertise. Your gateway to crypto mastery begins here!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="features section-padding bg-lb" id="features">
        <div className="container">
          <div className="row pb-5 justify-content-center">
            <div className="col-xl-12">
              <div className="section_heading">
                <span>Explore</span>
                <h3>
                  {/* Most trusted Canadian crypto exchange */}
                  Why Choose The Crypto Exchange?
                </h3>
                <p>Here are a few reasons why you should choose TCE</p>
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-md-3">
              <div className="features-content">
                <span>
                  <img
                    src={require("../../images/exchange_icon.png")}
                    alt=""
                    className="img-fluid"
                  />
                </span>
                <h4>
                  Buy/Sell and Withdraw with Ease
                </h4>
                <p>
                  Make all crypto buying, selling, and withdrawal transactions swiftly through our streamlined and advanced crypto wallet processing.
                </p>
                <a href="/Instant-Buy-Sell-and-Withdraw">
                  Learn more 
                  <span class="screen-reader-text">Details</span>
                  <i className="mdi mdi-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="features-content">
                <span>
                  <img
                    src={require("../../images/chart.png")}
                    alt=""
                    className="img-fluid"
                  />
                </span>
                <h4>Real Time Quotes</h4>
                <p>
                  Get access to all trusted liquidity providers and ensure the best possible rates.
                </p>
                <a href="/Real-Time-Quotes">
                  Learn more 
                  <span class="screen-reader-text">Details</span>
                  <i className="mdi mdi-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="features-content">
                <span>
                  <img
                    src={require("../../images/non_cust_icon.png")}
                    alt=""
                    className="img-fluid"
                  />
                </span>
                <h4>
                  Non Custodial
                </h4>
                <p>
                  The best cryptocurrency platform, providing full control over your digital assets with no-custodial policy.
                </p>
                <a href="/Non-Custodial">
                  Learn more  
                  <span class="screen-reader-text">Details</span>
                  <i className="mdi mdi-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="features-content">
                <span>
                  <img
                    src={require("../../images/crypto_icon.png")}
                    alt=""
                    className="img-fluid"
                  />
                </span>
                <h4>
                  Safe and Secure Crypto Wallet
                </h4>
                <p>
                  100+ cryptocurrencies on our safest crypto exchange available to trade with the securest blockchain technologies. 
                </p>
                <a href="/Safe-and-Secure-Crypto-Wallet">
                  Learn more 
                  <span class="screen-reader-text">Details</span>
                  <i className="mdi mdi-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="counter-section mt-5" id="market">
        <div className="container">
          <div className="card">
            <div className="card-body px-5 py-3">
              <div className="row text-center">
                <div className="col-md-12">
                  <div className="section_heading">
                    <span className="text-uppercase">Web3</span>
                    <h3>Partners</h3>
                  </div>
                  <div className="bcimlist mb-0">
                     <div className="row">
                    {/*  <div className="col-md-3">
                        <p>
                          <strong>API </strong> Powered by
                          <span>
                            <a
                              href="https://www.cybrid.xyz/"
                              target="_blank"
                              title="Cybrid"
                            >
                              <img
                                src={require("../../images/cybrid.png")}
                                alt=""
                              />
                            </a>
                          </span>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p>
                          <strong>KYC</strong> Verified by
                          <span>
                            <img
                              src={require("../../images/Persona.png")}
                              alt=""
                            />
                          </span>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p>
                          <strong>Banking</strong> Processed By
                          <span>
                            <img
                              src={require("../../images/Plaid_logo.png")}
                              alt=""
                            />
                          </span>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p>
                          <strong>APPLICATION</strong> Engineered By
                          <span>
                            <a
                              href="http://blaze.ws"
                              target="_blank"
                              title="BLAZE"
                            >
                              <img
                                src={require("../../images/blaze-ws.png")}
                                alt=""
                              />
                            </a>
                          </span>
                        </p>
                      </div>

                      <div className="col-md-12 mt-5">
                        <p className="mb-3">Supported banks</p>
                        <div className="usd-banks">
                          <span>
                            <img
                              src={require("../../images/royal_bank.png")}
                              alt=""
                            />
                          </span>
                          <span>
                            <img
                              src={require("../../images/Scotiabank.png")}
                              alt=""
                            />
                          </span>
                          <span>
                            <img
                              src={require("../../images/td-canada-trust.png")}
                              alt=""
                            />
                          </span>
                          <span>
                            <img
                              src={require("../../images/BMO_logo_Bank_of_Montreal.png")}
                              alt=""
                            />
                          </span>
                          <span>
                            <img
                              src={require("../../images/CIBC.png")}
                              alt=""
                            />
                          </span>
                          <span>
                            <img
                              src={require("../../images/Tangerine_Bank.png")}
                              alt=""
                            />
                          </span>
                          <span>
                            <img
                              src={require("../../images/American-Express.png")}
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </div> */}
                    {/* <Slider {...settings}> */}
                      <div className="col-md-3">
                        <p>
                          <strong>API </strong> Powered by
                          <span>
                            <a
                              href="https://www.cybrid.xyz/"
                              target="_blank"
                              title="Cybrid" 
                              rel="noreferrer"
                              tabindex="-1"
                            >
                              <img
                                src={require("../../images/cybrid.png")}
                                alt=""
                              />
                            </a>
                          </span>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p>
                          <strong>KYC</strong> Verified by
                          <span>
                            <img
                              src={require("../../images/Persona.png")}
                              alt=""
                            />
                          </span>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p>
                          <strong>Banking</strong> Processed By
                          <span>
                            <img
                              src={require("../../images/Plaid_logo.png")}
                              alt=""
                            />
                          </span>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p>
                          <strong>APPLICATION</strong> Engineered By
                          <span>
                            <a
                              href="http://blaze.ws"
                              target="_blank"
                              title="BLAZE" rel="noreferrer"
                            >
                              <img
                                src={require("../../images/blaze-ws.png")}
                                alt=""
                              />
                            </a>
                          </span>
                        </p>
                      </div>
                    {/* </Slider> */}
                    </div>
                  </div>
                  {/* <button type="submit" className="btn btn-primary">Get Started</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
            <div className="market section-padding page-section counter-section" id="market">
                <div className="container">                   
                            <div className="card">
                                <div className="card-body px-5 py-3"> 
                                <div className="row text-center">                                    
                                    <div className="col-md-12">
                                    <div className="section_heading">
                                    <span>The crypto exchange for everone</span>
                                    </div>
                                    <div className="bcimlist">
                                        <p><span>9M+</span>Clients</p>
                                        <p><span>190+</span>Countries supported</p>
                                        <p><span>$207B+</span>Quarterly trading volume</p>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Get Started</button>                                       
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="market section-padding page-section bg-lb owfsec" id="market">
        <div className="container">
          <div className="row pb-5 justify-content-center">
            <div className="col-xl-7">
              <div className="section_heading">
                <span>Our Work Flow</span>
                {/* <h3>Create your portfolio</h3> */}
              </div>
            </div>
          </div>
          {/* <Slider {...settings}> */}
          <div className="row">

            <div className="col-md-3">
              <div className="card sshn">
                <div className="sshnum">1</div>
                <div className="card-body ">
                  <img
                    src={require("../../images/workflow1.png")}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="section_subheading mb-0">

                    <h4>Create account</h4>
                    <p>
                      Register an Account and Get Started in Minutes
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card sshn">
                <div className="sshnum">2</div>
                <div className="card-body ">
                  <img
                    src={require("../../images/workflow2.png")}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="section_subheading mb-0">
                    <h4>Get KYC verified</h4>
                    <p>
                      Fast, Secure and Immediate KYC using the latest in Technology
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card sshn">
                <div className="sshnum">3</div>
                <div className="card-body ">
                  <img
                    src={require("../../images/workflow3.png")}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="section_subheading mb-0">
                    <h4>Deposit FIAT/ Crypto to wallet</h4>
                    <p>
                      Our platform makes it effortless to get your funds available to easily buy/sell and withdraw your crypto currencies
                    </p>
                  </div>
                </div>
              </div>


            </div>

            <div className="col-md-3">
              <div className="card sshn">
                <div className="sshnum">4</div>
                <div className="card-body ">
                  <img
                    src={require("../../images/workflow4.png")}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="section_subheading mb-0">
                    <h4>Buy and Sell​</h4>
                    <p>
                      Instantly access real-time quotes using multiple liquidity providers to guarantee the best rates
                    </p>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6">
              <div className="card sshn">
                <div className="sshnum">5</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4"><img
                      src={require("../../images/workflow5.png")}
                      alt=""
                      className="img-fluid h-auto"
                    /></div>
                    <div className="col-md-8">
                      <div className="section_subheading mb-0">
                        <h4>Create/Provide Wallet Address​</h4>
                        <p>
                          Download The Crypto Exchange Wallet to safely and securely store your Crypto Currency or Provide your Private Address
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card sshn">
                <div className="sshnum">6</div>
                <div className="card-body ">
                  <div className="row">

                    <div className="col-md-8">
                      <div className="section_subheading mb-0">
                        <h4>Withdraw your currency</h4>
                        <p>
                          Whether you're withdrawing fiat or cryptocurrencies, our streamlined process ensures your assets reach their intended location promptly and reliably
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <img
                        src={require("../../images/workflow6.png")}
                        alt=""
                        className="img-fluid h-auto"
                      />
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>



          {/* </Slider> */}
        </div>
      </div >
      <Footer1 />
    </>
  );
}

export default Landing;
