import React, { useState, useEffect } from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import {
    commonError,
    getMarketPrice,
    resetMarketPrice,
    resetgetMarketPriceFailed,
    resetGetCustomerAccountDetails,
} from "../../actions/customerActions";
import CustomerVerificationPopup from "../shared/CustomerVerificationPopup";
import { decryptPassword, formatCurrency } from "../../utils/Encryption";
import DashboardTransaction from "../shared/DashboardTransaction";
import ethereumLogo from "../../images/ethereum.png";
import bitcoinLogo from "../../images/bitcoin.png";
import bitcoincashLogo from "../../images/bch.png";
import litecoinLogo from "../../images/LTC.png";
import Gifloader from "../layout/gifloader";
import Exchange from "../shared/Exchange";
import DownloadWallet from "../shared/DownloadWallet";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import RegenerateCustomerToken from "../shared/regenerateCustomerToken";
import usdcLogo from "../../images/usdc.png";
import usdLogo from "../../images/usd.png";

function BuySell() {

    let livePriceInterval;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let tempToken = sessionStorage.getItem("guid");
    let deToken = decryptPassword(tempToken);
    const marKetPriceInterval = 5 ; //5 seconds
    const getMarketPriceResFailed = useSelector((state) => state.customerReducer.getMarketPriceFailed);
    const getMarketPriceRes = useSelector((state) => state.customerReducer.getMarketPrice);
    const getAccountDatais = useSelector((state) => state.customerReducer.getAccountData);



    const [liveSellBTC, setLiveSellBTC] = useState();
    const [liveSellETH, setLiveSellETH] = useState();
    const [liveSellUSDC, setLiveSellUSDC] = useState();

    const [liveSellBCH, setLiveSellBCH] = useState();
    const [liveSellLTC, setLiveSellLTC] = useState();
    const [accountData, setAccountData] = useState([]);
    const [fiatAccount, setFiatAccount] = useState({});
    const [getQuote, setShow] = useState(false);
    const [marketPrice, setMarketPrice] = useState([]);
    const [sPaymethod, setSPM] = useState(false);
    const [selectedObj, setSelectedObj] = useState(null);
    const [isCircleStarted, setIsCircleStarted] = useState(false);
    const [isLoader, setIsLoader] = useState(true);
    const [cryptoBalance, setCryptoBalance] = useState({});
    const [buyactive, setBuyactive] = useState(true);


    // calls the cybrid price api for every 5 seconds
    useEffect(() => {
        dispatch(getMarketPrice(sessionStorage.at));
        livePriceInterval = setInterval(() => {
            dispatch(getMarketPrice(sessionStorage.at))
            setIsCircleStarted(true)
        }, marKetPriceInterval * 1000);
        return () => {
            clearInterval(livePriceInterval);
        };
    }, []);

    useEffect(() => {
        if (getMarketPriceRes.length !== 0) {
            setIsCircleStarted(true)
            // after getting the market price array from api, it is modified using map adding logo, name & symbol
            const updatedMarketPrice = getMarketPriceRes?.map((item) => {
                let logo;
                let name;
                let symbol;
                if (item.symbol === "BTC-USD") {
                    logo = bitcoinLogo;
                    name = "Bitcoin";
                    symbol = "BTC";
                } else if (item.symbol === "ETH-USD") {
                    logo = ethereumLogo;
                    name = "Ethereum";
                    symbol = "ETH";
                } else if (item.symbol === "USDC-USD") {
                    logo = usdcLogo;
                    name = "USDC";
                    symbol = "USDC";
                } 
                
                // else if (item.symbol === "BCH-CAD") {
                //     logo = bitcoincashLogo;
                //     name = "Bitcoin Cash";
                //     symbol = "BCH";
                // } else if (item.symbol === "LTC-CAD") {
                //     logo = litecoinLogo;
                //     name = "Litecoin";
                //     symbol = "LTC";
                // }
                return {
                    ...item,
                    logo: logo,
                    name: name,
                    symbol: symbol,
                };
            });
            setMarketPrice(updatedMarketPrice);
            // this finds the object and set Sell price for each coin
            const bitCadObject = getMarketPriceRes?.find(
                (item) => item.symbol === "BTC-USD"
            );
            const ethCadObject = getMarketPriceRes?.find(
                (item) => item.symbol === "ETH-USD"
            );
            const usdcCadObject = getMarketPriceRes?.find(
                (item) => item.symbol === "USDC-USD"
            );

            // const bchCadObject = getMarketPriceRes?.find(
            //     (item) => item.symbol === "BCH-CAD"
            // );
            // const ltcCadObject = getMarketPriceRes?.find(
            //     (item) => item.symbol === "LTC-CAD"
            // );
            setLiveSellBTC(bitCadObject?.sell_price);
            setLiveSellETH(ethCadObject?.sell_price);
            setLiveSellUSDC(usdcCadObject?.sell_price);

            // setLiveSellBCH(bchCadObject?.sell_price);
            // setLiveSellLTC(ltcCadObject?.sell_price);
            dispatch(resetMarketPrice());
        }
    }, [getMarketPriceRes]);

    useEffect(() => {
        if (getMarketPriceResFailed && Object.keys(getMarketPriceResFailed).length !== 0) {
            let errObj = getMarketPriceResFailed
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message))
                dispatch(resetgetMarketPriceFailed())
                if (errObj.data.error_message == "Signature has expired" || errObj.data.message_code == "authentication_failed") {
                    navigate("/logout");
                }
            }
        }
    }, [getMarketPriceResFailed])

    useEffect(() => {
        if (
            Object.keys(getAccountDatais).length !== 0
        ) {
            // after getting the market price array from api 
            //each object is modified with divider,logo,name,symbol,livePrice,balance_usd,platform_balance

            const modifiedData = getAccountDatais.objects.map((account) => {
                let divider;
                let logo;
                let name;
                let symbol;
                let livePrice;
                let usdAvailable;
                if (account.asset === "BTC") {
                    logo = bitcoinLogo;
                    name = "Bitcoin";
                    symbol = "BTC";
                    divider = 100000000;
                    livePrice = liveSellBTC;
                } else if (account.asset === "ETH") {
                    logo = ethereumLogo;
                    name = "Ethereum";
                    symbol = "ETH";
                    divider = 1000000000000000000;
                    livePrice = liveSellETH;
                } else if (account.asset === "USDC") {
                    logo = usdcLogo;
                    name = "USDC";
                    symbol = "USDC";
                    divider = 1000000;
                    livePrice = liveSellUSDC;
                } 
                
                // else if (account.asset === "BCH") {
                //     logo = bitcoincashLogo;
                //     name = "Bitcoin Cash";
                //     symbol = "BCH";
                //     divider = 100000000;
                //     livePrice = liveSellBCH;
                // } else if (account.asset === "LTC") {
                //     logo = litecoinLogo;
                //     name = "Litecoin";
                //     symbol = "LTC";
                //     divider = 100000000;
                //     livePrice = liveSellLTC;
                // } 
                
                else if (account.asset === "USD") {
                    let balance;
                    logo = usdLogo;
                    name = "USD Dollar";
                    symbol = "USD";
                    balance = (account?.platform_balance / 100);
                    usdAvailable = (account?.platform_available / 100);
                    setFiatAccount({
                        ...account,
                        logo: logo,
                        name: name,
                        symbol: symbol,
                        platform_balance: balance,
                        balance_usd: balance,
                        platform_available: usdAvailable,
                    })
                }
                //coverts to crypto asset to user format
                const platform_balance_million_units =
                    account.platform_balance / divider;
                // Crypto available balance
                const platform_available_million_units =
                    account.platform_available / divider;
                //converts cents ot dollar
                const balance_usd =
                    (platform_available_million_units * livePrice) / 100;
                return {
                    ...account,
                    logo: logo,
                    name: name,
                    symbol: symbol,
                    platform_balance: platform_balance_million_units.toFixed(8),
                    platform_available: platform_available_million_units.toFixed(8),
                    balance_usd: balance_usd.toFixed(2),
                };
            });
            // filters only crypto asset and adds their total cad balance
            const totalCanadianBalance = modifiedData
                .filter((asset) => asset.type !== "fiat")
                .reduce(
                    (total, asset) => total + parseFloat(asset.balance_usd),
                    0
                );
            // finds and gives a ratio of each crypto type
            const assetsWithRatio = modifiedData.map((asset) => ({
                ...asset,
                ratio_percentage:
                    asset.type === "fiat"
                        ? 100
                        : ((parseFloat(asset.balance_usd) / totalCanadianBalance) * 100).toFixed(2),
            })).filter((asset) => asset.type !== "fiat");
            let sortedArr = sortArrayBySymbol(assetsWithRatio)
            setAccountData(sortedArr);
            setCryptoBalance(sortedArr[0])
            dispatch(resetGetCustomerAccountDetails());
            setIsLoader(false)
        }
    }, [getAccountDatais]);

    //this two  function triggered from child component ,which is passed as props,data transfer from child to parent
    const updateBuySelectedObj = (newBuySelectedObj) => {
        setBuyactive(true);
        setSelectedObj({ ...newBuySelectedObj, price: newBuySelectedObj?.buy_price });
        let tempObj = accountData && accountData?.find(e => e.symbol === newBuySelectedObj.symbol)
        setCryptoBalance(tempObj)
    };

    const updateSellSelectedObj = (newBuySelectedObj) => {
        setBuyactive(false);
        setSelectedObj({ ...newBuySelectedObj, price: newBuySelectedObj?.sell_price });
        let tempObj = accountData && accountData?.find(e => e.symbol === newBuySelectedObj.symbol)
        setCryptoBalance(tempObj)
    };

    const handleClose = () => setShow(false);
    const handleSPMClose = () => setSPM(false);

    const handleSPM = () => {
        setSPM(true);
        setShow(false);
    }

    const handlePP = () => setSPM(false);

    function sortArrayBySymbol(array) {
        //const symbolOrder = ['CAD', 'BCH', 'USDC', 'BTC', 'ETH', 'LTC'];
        const symbolOrder = ['USD', 'BTC', 'ETH', 'USDC'];
        return array.sort((a, b) => {
            const aSymbol = a.asset;
            const bSymbol = b.asset;
            const aIndex = symbolOrder.indexOf(aSymbol);
            const bIndex = symbolOrder.indexOf(bSymbol);
            return aIndex - bIndex;
        });
    };

    return (
        <>
            <RegenerateCustomerToken />
            <Header2 />
            <Sidebar />
            <div className="content-body dash-main exchange">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <Exchange priceInfo={marketPrice} updateBuySelectedObj={updateBuySelectedObj} updateSellSelectedObj={updateSellSelectedObj} fiatAccount={fiatAccount} />
                        <DownloadWallet/>
                        <div className="col-md-4 dash4col">
                            <div className="card fadeInDown">
                                <div className="card-header justify-content-center">
                                    <h4 className="card-title ">
                                        Balance Available
                                    </h4>
                                </div>
                                <div className="card-body">
                                    {
                                        isLoader ? (
                                            <div className="mt-3 largecoinbox">
                                                <Gifloader />
                                            </div>
                                        ) : (
                                            <div className="largecoinbox">
                                                {buyactive ?
                                                    <div>
                                                        <div className="coin_icontxt p-0">
                                                            <img
                                                                src={require('./../../images/usd.png')}
                                                                alt=""
                                                                className="me-3"
                                                                width="30"
                                                            />
                                                            <div className="lcbtxt">
                                                                <h3 className="fw-bold"><span className="small">$</span><span className="text-primary">{fiatAccount && fiatAccount.platform_available?.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                )}</span> <span className="small">USD</span></h3>
                                                            </div>
                                                        </div>
                                                        <div className="txt-links atlinks mb-3 d-flex align-items-center">
                                                            <Link to="/deposits" className="text-link btn btn-primary ms-2">Deposit USD</Link>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className="coin_icontxt p-0">
                                                            <img
                                                                src={cryptoBalance && cryptoBalance.logo}
                                                                alt=""
                                                                className="me-3"
                                                                width="30"
                                                            />
                                                            <div className="lcbtxt">
                                                                <h3 className="fw-bold"><span className="text-primary">{cryptoBalance && parseFloat(cryptoBalance.platform_available)}</span> <span className="small">{selectedObj && selectedObj.symbol}</span></h3>
                                                            </div>
                                                        </div>
                                                        <div className="txt-links atlinks mb-3 d-flex align-items-center">
                                                            <Link to="/deposits#crypto" className="text-link btn btn-primary ms-2">Deposit Crypto</Link>
                                                        </div>


                                                    </div>
                                                }

                                                {/* <h4 className="fw-bold mt-4">
                                                    <span className="text-primary">{cryptoBalance && cryptoBalance.platform_balance} </span> 
                                                    <img
                                                            src={cryptoBalance && cryptoBalance.logo}
                                                            alt=""
                                                            className="mb-1"
                                                            width="25"
                                                        /> */}
                                                {/* <span className="small"> {cryptoBalance && cryptoBalance.symbol}</span>   */}
                                                {/* <span className="text-primary"> {cryptoBalance && cryptoBalance.balance_usd}</span> 
                                                    <span className="small"> CAD</span>  */}
                                                {/* </h4> */}
                                            </div>
                                        )
                                    }

                                    {
                                        selectedObj !== null && Object.keys(selectedObj).length > 0 && !isLoader ? (
                                            <div className="mt-3">

                                                <div className="largecoinbox border-none">
                                                    <div className="coin_icontxt p-0 flex-column">
                                                        <h4 className="fw-bold">  {selectedObj && selectedObj.symbol} / USD Price</h4>
                                                        <div className="lcbtxt mb-3">
                                                            <h3 className="fw-bold"><span className="small">$</span><span className="text-primary">{selectedObj && formatCurrency((selectedObj.price / 100)?.toLocaleString(
                                                                undefined,
                                                                {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            )).replace("$", "")}</span></h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="text-center timecounter">
                                                    <CountdownCircleTimer
                                                        isPlaying={isCircleStarted}
                                                        duration={5}
                                                        colors={['#1355d7', '#F7B801', '#A30000']}
                                                        colorsTime={[5, 3, 0]}
                                                        onComplete={() => {
                                                            // do your stuff here
                                                            return { shouldRepeat: true } // repeat animation in 1.5 seconds
                                                        }}
                                                    >
                                                        {({ remainingTime }) => remainingTime}
                                                    </CountdownCircleTimer>
                                                    <p className="mb-0 mt-3 fw-bold mx-md-1">Price gets updated in every 5 seconds</p>
                                                </div>
                                            </div>

                                        ) : (
                                            <div className="my-5 py-5">
                                                <Gifloader />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            {/* <ExchangeRecentTransaction/> */}
                        </div>
                        
                        {/* <div className="col-md-4">
                            <Verifymodule />
                        </div> */}
                    </div>

                    {/* <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">
                                        Live Pricing{" "}
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="market-table">
                                        <div className="table-responsive">
                                            {livePriceLoader ?
                                                <div>
                                                    <Gifloader />
                                                </div>
                                                :
                                                <table className="table mb-0 table-responsive-sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Crypto Currencies</th>
                                                            <th className="text-end">Buy Price <small className="text-primary">(CAD)</small></th>
                                                            <th className="text-end">Sell Price <small className="text-primary">(CAD)</small></th>
                                                            <th className="text-center">Last Updated</th>
                                                            <th className="text-center">Graph</th>                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {marketPrice.length > 0 ? marketPrice.map((value, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td className="coin_icon">
                                                                        <div className="coin_icontxt">
                                                                            <img
                                                                                src={value.logo}
                                                                                alt=""
                                                                                className="me-2"
                                                                                width="24"
                                                                            />
                                                                            <span>
                                                                                {value.name}{" "}
                                                                                <b>{value.symbol}</b>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">$<span className="text-primary fw-bold">{(value.buy_price / 100).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })}</span></td>
                                                                    <td className="text-end">$<span className="text-primary fw-bold">{(value.sell_price / 100).toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })}</span></td>
                                                                    <td className="text-center">
                                                                        <span className="">
                                                                            {new Date(value.buy_price_last_updated_at).toLocaleString(undefined, {
                                                                                year: "numeric",
                                                                                month: "short",
                                                                                day: "numeric",
                                                                                hour: "numeric",
                                                                                minute: "numeric",
                                                                                hour12: true,
                                                                            })}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-center py-0 tinychart">
                                                                        <TinyChart marketPrices={value.graphData} />

                                                                    </td>
                                                                   
                                                                </tr>
                                                            )
                                                        })
                                                            : ""
                                                        }
                                                    </tbody>
                                                </table>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div>
                        <div className="card fadeInDown">
                            <div className="card-header">
                                <h4 className="card-title">Recent Transactions</h4>
                            </div>
                            <DashboardTransaction />
                        </div>
                    </div>
                </div>
            </div>

            {/* <Modal centered show={getQuote} size="lg" className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handleClose}><span>x</span></div>
                    <div className="getverified fadeInDown">
                        <Modal.Body>
                            <h2 className="mb-5 text-center">Your Quote</h2>
                            <div className="ppmethod">
                                <ul className="list-unstyled smplist">
                                    <li onClick={handlePP} className="border-none bg-light">
                                        <div className="spm-icon">
                                            <img
                                                src={require("../../images/ethereum.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>

                                        <div className="spm-text">
                                            1 ETH price -
                                        </div>

                                        <div className="spm-content">
                                            <p><span className="text-primary">$2512.90</span> USD</p>
                                        </div>
                                        <div className="spm-icon"></div>
                                    </li>
                                </ul>
                            </div>                            
                            <div className="ppmethod">
                                <ul className="list-unstyled smplist">
                                    <li >
                                        <div className="spm-content">
                                            Quote for 1000 USD
                                        </div>
                                        <div className="spm-icon">
                                            <img
                                                src={require("../../images/ethereum.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="spm-icon"><span className="text-primary me-2">0.00040</span> ETH</div>
                                    </li>
                                    <li>
                                        <div className="spm-content">
                                            Purchase value
                                        </div>
                                        <div className="spm-icon">
                                            <img
                                                src={require("../../images/ethereum.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="spm-icon"><span className="text-primary me-2">0.00040</span> ETH</div>
                                    </li>

                                    <li >
                                        <div className="spm-content">
                                            Payment
                                        </div>

                                        <div className="spm-icon"><span className="text-primary me-2">$1000</span> USD</div>
                                    </li>
                                    <li>
                                        <div className="spm-content">
                                            Conveyance fee
                                        </div>
                                        <div className="spm-icon"><span className="text-primary me-2">$0.99</span> USD</div>
                                    </li>

                                    <li >
                                        <div className="spm-content">
                                            Total
                                        </div>
                                        <div className="spm-icon"><span className="text-primary h3 fw-bold me-2">$1000.99 </span> USD</div>
                                    </li>
                                </ul>
                            </div>
                            <div className="ppmethod" onClick={handleSPM}>
                                <ul className="list-unstyled smplist">
                                    <li >
                                        <div className="spm-content">
                                            Select payment method
                                        </div>
                                        <div className="spm-icon">
                                            <div className="selectedpayment" >
                                                <div className="spicon"><i className="fa fa-credit-card"></i></div>
                                                <div className="spcontent">Debit/Credit Card</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="text-center">
                                <button className="btn btn-primary">Buy Now</button>
                            </div>

                            <div className="row mt-3 ">
                                <div className="col-md-4 small">ETH balance</div>
                                <div className="col-md-8 text-end small">0.022276014 ETH = $56.14 USD</div>
                            </div>
                        </Modal.Body>
                    </div>
                </div>
            </Modal> */}

            {/* <Modal centered show={sPaymethod} className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handleSPMClose}><span>x</span></div>

                    <div className="getverified fadeInDown">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Pay with
                        </Modal.Title>
                        <ul className="list-unstyled smplist">
                            <li onClick={handlePP}>
                                <div className="spm-icon">
                                    <img
                                        src={require("../../images/visa-card.png")}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="spm-content">
                                    <h4>Credit card</h4>
                                    <p>Ending with 1001********2552</p>
                                </div>
                                <div className="spm-icon"><i className="mdi mdi-chevron-right"></i></div>
                            </li>

                            <li onClick={handlePP}>
                                <div className="spm-icon"><img
                                    src={require("../../images/visa-card.png")}
                                    alt=""
                                    className="img-fluid"
                                />    </div>
                                <div className="spm-content">
                                    <h4>Debit card</h4>
                                    <p>Ending with 4757********9876</p>
                                </div>
                                <div className="spm-icon"><i className="mdi mdi-chevron-right"></i></div>
                            </li>

                            <li onClick={handlePP}>
                                <div className="spm-icon"><img
                                    src={require("../../images/purse.png")}
                                    alt=""
                                    className="img-fluid"
                                />    </div>
                                <div className="spm-content">
                                    <h4>FBO Wallet</h4>
                                    <p>1q23123******q23123</p>
                                </div>
                                <div className="spm-icon"><i className="mdi mdi-chevron-right"></i></div>
                            </li>

                            <li>
                                <div className="spm-content">
                                    <button type="button" className="btn btn-outline-primary"><i className="fa fa-plus me-2"></i> Add a payment method</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal> */}

            {/* <Modal centered show={PayPreview} className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handlePPC}><span>x</span></div>

                    <div className="getverified fadeInDown">
                        <div className="buy-sell-widget">
                            <Tabs
                                defaultActiveKey="buy"
                                id="uncontrolled-tab-example"
                            >
                                <Tab eventKey="buy" title="Buy">
                                    <div className="ppa-box">
                                        <span className="ppaexc"></span>
                                        <span className="pp-amount"><span className="ppasmall">$</span> 100</span>
                                        <span className="ppaexc">
                                            <img src={require("../../images/two-arrows.png")} alt="" className="img-fluid" />
                                            BTC
                                        </span>
                                    </div>
                                    <div className="ppmethod">
                                        <ul className="list-unstyled smplist">
                                            <li onClick={handlePP}>
                                                <div className="spm-text">
                                                    Buy
                                                </div>
                                                <div className="spm-icon">
                                                    <img
                                                        src={require("../../images/bitcoin.png")}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div className="spm-content">
                                                    <p>Bitcoin</p>
                                                </div>
                                                <div className="spm-icon"><i className="mdi mdi-chevron-right"></i></div>
                                            </li>
                                            <li onClick={handlePP}>
                                                <div className="spm-text">
                                                    Pay with
                                                </div>
                                                <div className="spm-icon">
                                                    <img
                                                        src={require("../../images/visa-card.png")}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div className="spm-content">
                                                    <p>Credit card</p>
                                                </div>
                                                <div className="spm-icon"><i className="mdi mdi-chevron-right"></i></div>
                                            </li>
                                        </ul>
                                    </div>

                                    <button type="button" name="submit" className="btn btn-primary w-100">Continue</button>

                                        <div className="row mt-3 ">
                                            <div className="col-md-4 small">BTC balance</div>
                                            <div className="col-md-8 text-end small">0.022276014 BTC = $885.91 CAD</div>
                                        </div>
                                </Tab>
                                <Tab eventKey="sell" title="Sell">
                                <div className="ppa-box">
                                        <span className="ppaexc"></span>
                                        <span className="pp-amount"><span className="ppasmall">$</span> 100</span>
                                        <span className="ppaexc">
                                            <img src={require("../../images/two-arrows.png")} alt="" className="img-fluid" />
                                            BTC
                                        </span>
                                    </div>
                                    <div className="ppmethod">
                                        <ul className="list-unstyled smplist">
                                            <li onClick={handlePP}>
                                                <div className="spm-text">
                                                    Buy
                                                </div>
                                                <div className="spm-icon">
                                                    <img
                                                        src={require("../../images/bitcoin.png")}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div className="spm-content">
                                                    <p>Bitcoin</p>
                                                </div>
                                                <div className="spm-icon"><i className="mdi mdi-chevron-right"></i></div>
                                            </li>
                                            <li onClick={handlePP}>
                                                <div className="spm-text">
                                                    Pay with
                                                </div>
                                                <div className="spm-icon">
                                                    <img
                                                        src={require("../../images/visa-card.png")}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div className="spm-content">
                                                    <p>Credit card</p>
                                                </div>
                                                <div className="spm-icon"><i className="mdi mdi-chevron-right"></i></div>
                                            </li>
                                        </ul>
                                    </div>

                                    <button type="button" name="submit" className="btn btn-primary w-100">Continue</button>

                                        <div className="row mt-3 ">
                                            <div className="col-md-4 small">BTC balance</div>
                                            <div className="col-md-8 text-end small">0.022276014 BTC = $885.91 CAD</div>
                                        </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </Modal> */}
            <CustomerVerificationPopup />
        </>
    );
}

export default BuySell;
