import React, { useState, useEffect, useRef } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { decryptPassword } from "../../utils/Encryption";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePlaidLink } from 'react-plaid-link';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import {
  getWorkFlow,
  commonError,
  commonSuccess,
  resetGetWorkFlow,
  resetGetWorkFlowFailed,
  resetPostWorkflowsAction,
  postExternalBankAccountAction,
  resetPostWorkflowsActionFailed,
  getExternalBankAccountListAction,
  postExternalBankWalletAccountAction,
  deleteExternalBankWalletAccountAction,
  getExternalBankAccountWalletListAction,
  resetPostExternalBankAccountWalletAction,
  resetDeleteExternalBankWalletAccountAction,
  resetPostExternalBankAccountWalletActionFailed,
  resetGetExternalBankAccountWalletListActionFailed,
  getMarketPrice,
  resetMarketPrice
} from "../../actions/customerActions";
import {
  createOtp, resetCreateOtp, resetCreateOtpFailed, verifyOtp, resetVerifyOtp, checkWallet, resetCheckWalletSuccess, resetCheckWalletFailed,
} from '../../actions/authAction';
import ButtonLoader from "../layout/buttonLoader";
import Header2 from '../layout/header2';
import Sidebar from '../layout/sidebar';
import RegenerateCustomerToken from "../shared/regenerateCustomerToken";
import bitcoinlogo from "../../images/bitcoin.png";
import usdclogo from "../../images/usdc.png";
import ethlogo from "../../images/ethereum.png";

function ExternalWallet() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let tempToken = sessionStorage.getItem("guid");
  let deToken = decryptPassword(tempToken);


  const createOtpData = useSelector((state) => state.authReducer.createOtpData);
  const createOtpFailed = useSelector((state) => state.authReducer.createOtpFailed);
  const verifyOtpData = useSelector((state) => state.authReducer.verifyOtpData);
  const postWorkflow = useSelector((state) => state.customerReducer.postWorkflowData);
  const postWorkflowFailed = useSelector((state) => state.customerReducer.postWorkflowFailed);
  const getWorkflowData = useSelector((state) => state.customerReducer.getWorkflowData);
  const getWorkflowDataFailed = useSelector((state) => state.customerReducer.getWorkflowFailed);
  const postExternalBankWallet = useSelector((state) => state.customerReducer.postExternalBankWalletData);
  const postExternalBankWalletFailed = useSelector((state) => state.customerReducer.postExternalBankWalletFailed);
  const getExternalBankAccountWalletList = useSelector((state) => state.customerReducer.getExternalBankWalletAccountData);
  const getExternalBankAccountWalletListFailed = useSelector((state) => state.customerReducer.getExternalBankWalletAccountFailed);
  const deleteExternalWallet = useSelector((state) => state.customerReducer.deleteExternalBankWalletAccountData);
  const deleteExternalWalletFailed = useSelector((state) => state.customerReducer.deleteExternalBankWalletAccountFailed);
  const getMarketPriceData = useSelector((state) => state.customerReducer.getMarketPrice);
  const checkWalletData = useSelector((state) => state.authReducer.checkWalletData);
  const checkWalletFailedData = useSelector((state) => state.authReducer.checkWalletFailedData);

  const plaidDivRef = useRef(null);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [remainingOtpAttempts, setRemainingOtpAttempts] = useState(5);
  const [externalWalletList, setExternalWalletList] = useState();
  const [isCircleStarted, setIsCircleStarted] = useState(false);
  const [showAddWallet, setShowAddWallet] = useState(false);
  const [walletBtnLoad, setWalletBtnLoad] = useState(false);
  const [isWalletView, setIsWalletView] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createOTP, setCreateOTP] = useState(false);
  const [plaidToken, setPlaidToken] = useState('');
  const [walletName, setWalletName] = useState('');
  const [assetName, setAssetName] = useState('');
  const [otpString, setOtpString] = useState("");
  const [copied, setCopied] = useState(false);
  const [cryptoSymbols, setCryptoSymbols] = useState([]);

  const inputRefs = useRef([]);

  const handleAddWallet = () => setShowAddWallet(true);

  const handleAddWalletInAction = (selectedAsset) => {
    setAssetName(selectedAsset);
    setShowAddWallet(true);
  }

  const handleEmptyStates = () => {
    setWalletName("");
    setAssetName("");
    setWalletAddress("");
    setOtpValues(["", "", "", "", "", ""]);
  }

  const handleAddWalletClose = () => {
    setShowAddWallet(false);
    setCreateOTP(false);
    setIsLoading(false);
    handleEmptyStates();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const pastedValues = pastedData.split("").slice(0, otpValues.length);
    const updatedOtpValues = Array.from(otpValues);

    for (let i = 0; i < updatedOtpValues.length; i++) {
      if (pastedValues.length > 0) {
        updatedOtpValues[i] = pastedValues.shift();
      } else {
        updatedOtpValues[i] = "";
      }
    }
    setOtpValues(updatedOtpValues);
    setOtpString(updatedOtpValues.join(""));
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = value;

    setOtpValues(updatedOtpValues);
    setOtpString(updatedOtpValues.join(""));

    // Move to the next input field if a digit is entered
    if (value !== "" && index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  useEffect(() => {
    dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken))
    dispatch(getExternalBankAccountWalletListAction(sessionStorage.at))
    dispatch(getMarketPrice(sessionStorage.at))
  }, [])

  useEffect(() => {
    if (getMarketPriceData?.length !== 0) {
      const symbols = getMarketPriceData?.map((price) => {
        const assetName = price?.symbol?.replace(/-USD$/, "");
        return {
          assetName
        }
      })
      setCryptoSymbols([{assetName : ""}, ...symbols]);
      dispatch(resetMarketPrice());
    }
  }, [getMarketPriceData]);

  useEffect(() => {
    if (Object.keys(getExternalBankAccountWalletList).length !== 0) {
      const externalBankData =  getExternalBankAccountWalletList.objects?.filter(i => i.state != "deleted");
      const addLogoToBankData = externalBankData?.length > 0 ? externalBankData?.map((data)=> {
        let logo;
        if(data?.asset === "BTC") {
          logo = bitcoinlogo
        }else if(data?.asset === "ETH") {
          logo = ethlogo
        }else if(data?.asset === "USDC") {
          logo = usdclogo
        }
        // else if(data?.asset === "BCH") {
        //   logo = bchlogo
        // }else if(data?.asset === "LTC") {
        //   logo = ltclogo
        // }

        return {
          ...data,
          logo:logo
        }
      }) : [];
      setExternalWalletList(addLogoToBankData)
    }
  }, [getExternalBankAccountWalletList])

  // useEffect(() => {
  //   if (Object.keys(postWorkflow).length !== 0) {
  //     dispatch(getWorkFlow(sessionStorage.at, postWorkflow.guid));
  //     dispatch(resetPostWorkflowsAction());
  //   }
  // }, [postWorkflow]);

  // useEffect(() => {
  //   if (Object.keys(getWorkflowData).length !== 0) {
  //     setPlaidToken(getWorkflowData.plaid_link_token);
  //     dispatch(resetGetWorkFlow());
  //   }
  // }, [getWorkflowData]);

  // useEffect(() => {
  //   if (plaidToken) {
  //     setTimeout(() => {
  //       plaidDivRef.current.click();
  //     }, 1000);
  //   }
  // }, [plaidToken]);

  useEffect(() => {
    if (Object.keys(postExternalBankWallet).length != 0) {
      dispatch(commonSuccess("Your wallet has been added successfully!"));
      setShowAddWallet(false);
      setIsLoading(false);
      setRemainingOtpAttempts(5);
      setCreateOTP(false);
      setOtpValues(["", "", "", "", "", ""])
      dispatch(getExternalBankAccountWalletListAction(sessionStorage.at));
      // dispatch(EmailNotification({
      //   type: "add-external-wallet",
      //   customer_guid: deToken,
      // }))
      dispatch(resetPostExternalBankAccountWalletAction())
    }
  }, [postExternalBankWallet]);

  // useEffect(() => {
  //   if (Object.keys(postWorkflowFailed).length !== 0) {
  //     let errObj = postWorkflowFailed
  //     if (errObj && errObj.data && errObj.data.error_message) {
  //       dispatch(commonError(errObj.data.error_message))
  //       dispatch(resetPostWorkflowsActionFailed())
  //     }
  //   }
  // }, [postWorkflowFailed])

  // useEffect(() => {
  //   if (Object.keys(getWorkflowDataFailed).length !== 0) {
  //     let errObj = getWorkflowDataFailed
  //     if (errObj && errObj.data && errObj.data.error_message) {
  //       dispatch(commonError(errObj.data.error_message))
  //       dispatch(resetGetWorkFlowFailed())
  //     }
  //   }
  // }, [getWorkflowDataFailed])

  useEffect(() => {
    if (Object.keys(postExternalBankWalletFailed).length !== 0) {
      let errObj = postExternalBankWalletFailed
      if (errObj?.data?.message_code === "data_exists") {
        dispatch(commonError("The wallet you are trying to add has already been added"))
        setWalletBtnLoad(false);
        handleAddWalletClose();
        dispatch(resetPostExternalBankAccountWalletActionFailed());
      } else if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        setWalletBtnLoad(false);
        handleAddWalletClose();
        dispatch(resetPostExternalBankAccountWalletActionFailed());
      }
    }
  }, [postExternalBankWalletFailed])

  useEffect(() => {
    if (Object.keys(getExternalBankAccountWalletListFailed).length !== 0) {
      let errObj = getExternalBankAccountWalletListFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetGetExternalBankAccountWalletListActionFailed())
        if (errObj.data.error_message == "Signature has expired" || errObj.data.message_code == "authentication_failed") {
          navigate("/logout");
        }
      }
    }
  }, [getExternalBankAccountWalletListFailed])

  useEffect(() => {
    if (Object.keys(deleteExternalWallet).length !== 0) {
      dispatch(commonSuccess("Your wallet has been removed successfully!"));
      dispatch(resetDeleteExternalBankWalletAccountAction())
      dispatch(getExternalBankAccountWalletListAction(sessionStorage.at, deToken))
    }
  }, [deleteExternalWallet]);

  useEffect(() => {
    if (Object.keys(deleteExternalWalletFailed).length !== 0) {
      let errObj = deleteExternalWalletFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetGetExternalBankAccountWalletListActionFailed())
        dispatch(getExternalBankAccountWalletListAction(sessionStorage.at, deToken))
      }
    }
  }, [deleteExternalWalletFailed]);

  const handleSubmit = (event) => {
    if(walletBtnLoad) {
      return;
    }
    if (walletName == '' || assetName == '' || walletAddress == '') {
      dispatch(commonError('Please fill out all the required details'));
      return;
    }
    setWalletBtnLoad(true);
    let email = sessionStorage.getItem("user_email");
    dispatch(checkWallet({address: walletAddress, assetName: assetName}));
  };

  const handleVerifyOtp = () => {
    if(isLoading) {
      return;
    }
    if (otpValues.some(value => value === "")) {
      dispatch(commonError("Please fill all the OTP fields"));
      return;
    }
    let email = sessionStorage.getItem("user_email");
    setIsLoading(true);
    dispatch(verifyOtp("", otpString , deToken));
  }

  useEffect(() => {
    if (createOtpFailed && Object.keys(createOtpFailed)?.length > 0) {
      setWalletBtnLoad(false);
      dispatch(resetCreateOtpFailed());
    }
  }, [createOtpFailed]);

  useEffect(() => {
    if (checkWalletData && Object.keys(checkWalletData)?.length > 0) {
      if (checkWalletData?.statusCode === 200) {
        dispatch(createOtp('', deToken));
      }
      dispatch(resetCheckWalletSuccess());
    }
  }, [checkWalletData]);

  useEffect(() => {
    if (checkWalletFailedData &&Object.keys(checkWalletFailedData)?.length > 0) {
      dispatch(commonError(checkWalletFailedData?.message));
      setWalletBtnLoad(false);
      dispatch(resetCheckWalletFailed());
    }
  }, [checkWalletFailedData]);


  useEffect(() => {
    if (createOtpData?.data) {
      const message = createOtpData?.data?.message;
      dispatch(commonSuccess(message.charAt(0).toUpperCase() + message.slice(1)));
      setCreateOTP(true);
      setIsCircleStarted(true);
      setWalletBtnLoad(false);
      dispatch(resetCreateOtp());
    }
  }, [createOtpData]);

  useEffect(() => {
    if (verifyOtpData?.data?.data) {
      const concatenatedString = Object.values(verifyOtpData.data.data).join("");
      if (concatenatedString !== "verified") {
        setIsLoading(false);
        dispatch(commonError("Please enter a valid OTP"));
        setRemainingOtpAttempts((prevAttempts) => prevAttempts - 1);
      } else if (concatenatedString === "verified") {
        const reqData = {
          name: walletName,
          customer_guid: decryptPassword(sessionStorage.guid),
          asset: assetName,
          address: walletAddress
        };
        handleEmptyStates();
        dispatch(postExternalBankWalletAccountAction(sessionStorage.at, reqData));
      }
      dispatch(resetVerifyOtp());
    }
  }, [verifyOtpData]);

  useEffect(() => {
    if (remainingOtpAttempts <= 0) {
      setOtpValues(["", "", "", "", "", ""]);
      setRemainingOtpAttempts(5);
      setCreateOTP(false);
      setIsLoading(false);
      dispatch(commonError("You have exceeded the maximum number of OTP verification attempts."));
    }
  }, [remainingOtpAttempts])

  const handleWalletDelete = (guid) => {
    dispatch(deleteExternalBankWalletAccountAction(sessionStorage.at, guid));
  }

  // const onLinkSuccess = (public_token, metadata) => {
  //   const bankaccount = {
  //     "name": "Plaid Checking",
  //     "account_kind": "plaid",
  //     "customer_guid": deToken,
  //     "asset": "CAD",
  //     "plaid_public_token": public_token,
  //     "plaid_account_id": metadata.account.id,
  //     "plaid_institution_id": metadata.institution.institution_id,
  //     "plaid_account_mask": metadata.account.mask,
  //     "plaid_account_name": metadata.account.name
  //   };
  //   dispatch(postExternalBankAccountAction(sessionStorage.at, bankaccount));
  // };

  // const config = {
  //   onSuccess: onLinkSuccess,
  //   onExit: (err, metadata) => { },
  //   onEvent: (eventName, metadata) => { },
  //   token: plaidToken
  // };

  // const { open, exit, ready } = usePlaidLink(config);

  // const openplaid = () => open();

  const copyaddress = (guid) => {
    const textArea = document.createElement('textarea');
    textArea.value = guid;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    dispatch(commonSuccess('Text copied to clipboard'));
    setCopied(true);

    // Reset the "Copied" text back to "Copy" after a delay (e.g., 2 seconds).
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const setExternalWalletClassName = (state) => {
    if (state === "completed") {
      return "mw-badge badge badge-success text-capitalize"
    } else if (state === "deleted" || state === "failed") {
      return "mw-badge badge badge-danger1 text-capitalize"
    } else {
      return "mw-badge badge badge-warning text-capitalize"
    }
  }


  return (
    <>
    <RegenerateCustomerToken/>
      <Header2 />
      <Sidebar />
      <div className="content-body dash-main external-wallet">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">


              <div className="card">
                <div className="card-header d-block">
                  <div className="row align-items-center pay_head">
                    <div className="col-md-6">
                      <h4 className="card-title">
                        External Wallet{" "}
                      </h4>
                    </div>

                    <div className="col-md-6">
                      {externalWalletList && externalWalletList.length > 0 ?
                        <div className="toprhtbtns">
                          <button type="button" name="submit" className="btn btn-primary" onClick={() => handleAddWallet()} >Add external wallet</button>
                          {/* <div ref={plaidDivRef} name="submit" className="d-none" onClick={openplaid}>plaid</div> */}
                        </div> : ''}
                    </div>
                  </div>
                </div>

                <div className="card-body py-0">
                  {externalWalletList && externalWalletList.length > 0 ?
                    <div className="transaction-table">
                      <div className="table-responsive">
                        <table className="table mb-0 table-responsive-sm">
                          <thead>
                            <tr>
                              <th>Asset</th>
                              <th>Name</th>
                              <th>State</th>
                              <th>Address</th>
                              <th>Action</th>
                              {/* <th>Created At</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {externalWalletList && externalWalletList.map((account, index) => (
                              <tr key={index}>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                  <>
                                  <img src={account.logo} alt="asset_logo" className="me-2" width="24" />
                                  {account.asset}
                                  </>
                                </td>
                                <td>
                                  {account.name}
                                </td>
                                <td>
                                  <span className={setExternalWalletClassName(account.state)}>
                                    {account.state}
                                  </span>
                                </td>
                                <td  style={{ whiteSpace: 'nowrap' }}>
                                  {account.address}
                                    <div className="word-break d-inline"> <OverlayTrigger overlay={
                                        <Tooltip>
                                              {copied ?"Copied":"Copy"}
                                            </Tooltip>
                                        }>
                                            <span className="text-primary" type="button" id="button-addon2" onClick={() => copyaddress(account.address)}><i className="mdi mdi-content-copy"></i></span>
                                      </OverlayTrigger>
                                    </div>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    {/* <button onClick={() => handleWalletDelete(account.guid)} className="btn btn-light btn-sm btn-icon btn-icon-delete" ><i className="mdi mdi-delete"></i></button> */}
                                    <button onClick={() => handleAddWalletInAction(account.asset)} className="btn btn-light btn-sm btn-icon btn-icon-add ms-2" ><i className="mdi mdi-plus"></i></button>
                                  </div>
                                </td>
                                {/* <td>
                                      {new Date(account.created_at).toLocaleString(undefined, {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      })}
                                    </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    :
                    <div className="smplistnolist">
                      <div className="spm-icon ciricon">
                        <img src={require('./../../images/wallet-1.png')} alt="" />
                      </div>
                      <div className="spm-content">
                        <h4>Add external wallet</h4>
                        <p className="mb-3 small">Get ready to trade</p>
                        {/* <p className='text-primary'>Why this is important?</p> */}
                        <div className="spm-icon">
                          <button type="button" name="submit" className="btn btn-primary" onClick={() => handleAddWallet()} >Add external wallet</button>
                          {/* <div ref={plaidDivRef} name="submit" className="d-none" onClick={openplaid}>plaid</div> */}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>

            {/* <div className="col-md-4">
              <Verifymodule />
            </div> */}
          </div>

        </div>
      </div>

      <Modal centered show={showAddWallet} className="tce-popup fadeInDown">
        <div className="tce-ppcon">
          <div className="tce-ppcls" onClick={handleAddWalletClose}><span>x</span></div>
          {showAddWallet && (
            <div className="getverified fadeInDown">
              <Modal.Title id="contained-modal-title-vcenter">
                Add your wallet address
              </Modal.Title>
              <Modal.Body>
                {createOTP ? (
                  <div className="row justify-content-center h-100 align-items-center">
                    <div className="d-block fadeInDown optarea">
                      <div>
                        <div className="card-header justify-content-center border-0 flex-column">
                          <h2 className="mb-2 ggtimefont">Verification</h2>
                          <p className="mb-0 text-center px-5">
                            Please enter the 6-digit verification code that was sent to your Email.
                          </p>
                        </div>

                        <div className="d-flex align-items-center justify-content-center mb-3">
                          <CountdownCircleTimer
                            size={80}
                            isPlaying={isCircleStarted}
                            duration={80}
                            strokeWidth={10}
                            colors={['#1355d7', '#F7B801', '#FFA500', '#A30000']}
                            colorsTime={[60, 40, 20, 0]}
                            onComplete={() => setCreateOTP(false)}
                          >
                            {({ remainingTime }) => remainingTime}
                          </CountdownCircleTimer>
                        </div>

                        <div className="card-body d-flex align-items-center justify-content-center">
                          <div className="spm-icon logotpverify">
                            {otpValues.map((value, index) => (
                              <input
                                className="otp form-control"
                                type="text"
                                value={value}
                                onChange={(e) => handleChange(e, index)}
                                maxLength="1"
                                onPaste={handlePaste}
                                ref={(ref) => (inputRefs.current[index] = ref)}
                                key={index}
                              />
                            ))}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center text-center">
                          <button
                            type="button"
                            className="btn btn-light"
                            style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                            onClick={() => {
                              setCreateOTP(false);
                              setOtpValues(["", "", "", "", "", ""]);
                            }}
                            disabled={isLoading}
                          >
                            <span className="text-dark">Back</span>
                          </button>
                          <button
                            type="submit"
                            className={`btn btn-primary btn-loader m-4 ${isLoading ? "active" : ""
                              }`}
                            onClick={() => handleVerifyOtp()}
                          >
                            <span>Verify Otp</span>
                            <ButtonLoader />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body">
                    <form
                      method="post"
                      name="myform"
                      className="currency_validate fadeInDown"
                    >
                      <div className="mb-3">
                        <label>Nick Name</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setWalletName(e.target.value)}
                          value={walletName}
                          autoComplete="off"
                        />
                      </div>
                      <div className="mb-3">
                        <label>Select Coin</label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setAssetName(e.target.value);
                          }}
                          value={assetName}
                        >
                          {
                            cryptoSymbols?.length !== 0 && (
                              cryptoSymbols?.map((crypto) => (
                                <>
                                <option value={crypto?.assetName} disabled={crypto?.assetName === ""}>
                                  {crypto?.assetName}
                                </option>
                                </>
                              ))
                            )
                          }

                        </select>
                      </div>
                      <div className="mb-3">
                        <label>Wallet Address</label>
                        <div className="input-group">
                          <input
                            // type={isWalletView ? "text" : "password"}
                            type="text"
                            className="form-control"
                            onChange={(e) => setWalletAddress(e.target.value)}
                            value={walletAddress}
                            autoComplete="off"
                          />
                          {/* <div className="input-group-append" onClick={() => setIsWalletView(!isWalletView)}>
                            <span className="input-group-text bg-transparent cursor-pointer">
                              {isWalletView ? <FontAwesomeIcon icon="eye" /> : <FontAwesomeIcon icon="eye-slash" />}
                            </span>
                          </div> */}
                        </div>
                      </div>

                      <div className="text-center tbtnbox mt-5" style={{ cursor: walletBtnLoad ? "not-allowed" : "pointer" }}>
                        <button
                          type="button"
                          className="btn btn-light me-3"
                          disabled={walletBtnLoad}
                          onClick={() => {
                            handleAddWalletClose();
                            setWalletBtnLoad(false);
                          }}
                        >
                          <span className="text-dark">Cancel</span>
                        </button>

                        <button
                             type="button"
                             onClick={() => handleSubmit()}
                             className={`btn btn-primary btn-loader ${walletBtnLoad ? "active" : ""
                            }`}
                          >
                            <span>Submit</span>
                            <ButtonLoader />
                          </button>
                      </div>
                    </form>
                  </div>
                )}
              </Modal.Body>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default ExternalWallet;