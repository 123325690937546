import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  commonError,
  postDepositaddress,
  getDepositaddressGuid,
  getDepositaddress,
  commonSuccess,
  resetPostDepositAddressActionFailed
} from "../../actions/customerActions";
import ButtonLoader from "../layout/buttonLoader";
import Gifloader from "../layout/gifloader";
import bitcoinLogo from "../../images/bitcoin.png";
import ethereumLogo from "../../images/ethereum.png";
import usdcEthereumLogo from "../../images/usdc.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const Depositcrypto = () => {
  const dispatch = useDispatch();
  const postDepositInterval = 2 ; //2 seconds

  const getDepositaddressData = useSelector((state) => state.customerReducer.getDepositaddressData);
  const getDepositaddressGuidData = useSelector((state) => state.customerReducer.getDepositaddressGuidData);
  const getAccountDatais = useSelector((state) => state.customerReducer.getAccountData);
  const postDepositaddressDatas = useSelector((state) => state.customerReducer.postDepositaddressData);
  const postDepositAddressFailed = useSelector((state) => state.customerReducer.postDepositAddressFailed);
  // const getExternalBankAccountWalletList = useSelector((state) => state.customerReducer.getExternalBankWalletAccountListData);


  const [isloader, setIsLoader] = useState(true);
  const [isGetQuote, setGQ] = useState(false);
  const [depositAddress, setdepositAddress] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [depositAddressList, setDepositAddressList] = useState();
  const [accountList, setAccountList] = useState();
  const [seletedLogo, setSeletedLogo] = useState();
  const [copied, setCopied] = useState(false);

  const getNewDepositGuidInterval = useRef();


  useEffect(() => {
    // dispatch(getExternalBankAccountWalletListAction(sessionStorage.at))
    dispatch(getDepositaddress(sessionStorage.at))
  }, []);

  useEffect(() => {
    if (getDepositaddressData && getDepositaddressData.objects != undefined) {
      if (Object.keys(getDepositaddressData).length !== 0) {
        setDepositAddressList(getDepositaddressData.objects);
        setIsLoader(false);
      }
    }
  }, [getDepositaddressData])

  useEffect(() => {
    if (Object.keys(getDepositaddressGuidData).length !== 0 && getDepositaddressGuidData != undefined) {
      if (getDepositaddressGuidData.address) {
        setdepositAddress(getDepositaddressGuidData);
        setIsBtnLoading(false);
        dispatch(getDepositaddress(sessionStorage.at));
        clearInterval(getNewDepositGuidInterval.current);
      }
    }
  }, [getDepositaddressGuidData])

  useEffect(() => {
    if (postDepositaddressDatas.length != 0) {
      let depositAddressGuid = postDepositaddressDatas.guid;
      getNewDepositGuidInterval.current = setInterval(() => {
        dispatch(getDepositaddressGuid(sessionStorage.at, depositAddressGuid))
      }, postDepositInterval * 1000);
    }
  }, [postDepositaddressDatas])

  useEffect(() => {
    if (Object.keys(postDepositAddressFailed).length !== 0) {
      let errObj = postDepositAddressFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message));
        dispatch(resetPostDepositAddressActionFailed());
        setIsBtnLoading(false);
      }
    }
  }, [postDepositAddressFailed])

  useEffect(() => {
    if (getAccountDatais.length !== 0) {
      const userAccount = getAccountDatais.objects?.find(
        (account) => account.asset === "USD"
      );

      
      // Determine the divider, logo, name, symbol and livePrice based on the account asset property
      const modifiedData = getAccountDatais.objects
        ?.filter((account) => account.asset !== "USD")
        .map((account) => {
          let divider;
          let logo;
          let name;
          let symbol;
          let livePrice;
          if (account.asset === "BTC") {
            logo = bitcoinLogo;
            name = "Bitcoin";
            symbol = "BTC";
            divider = 100000000;
          } else if (account.asset === "ETH") {
            logo = ethereumLogo;
            name = "Ethereum";
            symbol = "ETH";
            divider = 1000000000000000000;
          } else if (account.asset === "USDC") {
            logo = usdcEthereumLogo;
            name = "USDC";
            symbol = "USDC";
            divider = 1000000;
          } 

          // Add platform balance and balance canadian in modified data
          const platform_balance_million_units =
            account.platform_balance / divider;
          const balance_canadian =
            (platform_balance_million_units * livePrice) / 100;
          return {
            ...account,
            logo: logo,
            name: name,
            symbol: symbol,
            platform_balance: platform_balance_million_units,
            balance_canadian: balance_canadian.toFixed(3), // Format to six decimal places
          };
        });
      setAccountList(modifiedData);
      setSeletedLogo(modifiedData[0].logo)
    }
  }, [getAccountDatais]);


  const copyaddress = () => {
    const textArea = document.createElement('textarea'); // Create a new <textarea> element in the DOM.
    textArea.value = depositAddress?.address; // Set the value of the <textarea> to the provided 'guid'.
    document.body.appendChild(textArea); // Append the <textarea> element to the document's body.
    textArea.select(); // Select the content within the <textarea>.
    document.execCommand('copy'); // Execute the 'copy' command to copy the selected text to the clipboard.
    document.body.removeChild(textArea); // Remove the temporary <textarea> element from the document's body.
    setCopied(true);

    // Reset the "Copied" text back to "Copy" after a delay (e.g., 2 seconds).
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isBtnLoading) {
      return;
    }
    
    const account_guid = event.target.elements.account.value;

    if (account_guid === null || account_guid === "") {
      dispatch(commonError("Please select a account"));
      return;
    }

    const foundObject = depositAddressList.find(item => item.account_guid === account_guid);
    if (foundObject) {
      // Object with the given guid found
      setdepositAddress(foundObject);
      // console.log(foundObject);
    } else {
      // Object with the given guid not found
      // console.log(false);
      setIsBtnLoading(true);
      const reqData = {
        account_guid: account_guid
      };
      dispatch(postDepositaddress(sessionStorage.at, reqData));
    }
  };

  const handleCurrencyChange = (event) => {
    const selectedSymbol = event.target.value;
    const currObj = accountList.find(
      (currency) => currency.guid === selectedSymbol
    );
    setSeletedLogo(currObj.logo)
    setdepositAddress("");
  }
  const convertSymbolToName = (asset) => {
    if (asset === "BTC") {
      return "Bitcoin";
    } else if (asset === "ETH") {
      return "Ethereum";
    } else if (asset === "USDC") {
      return "USDC";
    }
  };

  return (
    <>
      {isloader ? (
        <>
          <div className="justify-content-center d-flex align-item-center my-5 py-5">
            <Gifloader />
          </div>
        </>
      ) : (
        <>
          <form
            method="post"
            name="myform"
            className="currency_validate fadeInDown"
            onSubmit={handleSubmit}
          >
            <div className={isGetQuote ? "d-none" : ""}>
              <div className="mb-3">
                <label className="me-sm-2">Select Account</label>
                <div className="input-group mb-3 igpcust">
                  <div className="input-group-prepend">
                    <label className="input-group-text">
                      <img
                        src={seletedLogo}
                        alt=""
                        className="img-fluid"
                      />
                    </label>
                  </div>
                  <select
                    name="account"
                    className="form-control"
                    onChange={(e) => handleCurrencyChange(e)}
                  >
                    {accountList?.map((item) => (
                      <option key={item.symbol} value={item.guid} >
                        {`${item.symbol}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              
              <div className="text-center">
                <button
                  type="submit"
                  className={`btn btn-primary mt-3 btn-loader  ${isBtnLoading ? "active" : ""}`}
                >
                  <span>Generate Deposit Address</span>
                  <ButtonLoader />
                </button>

                {depositAddress?.address ?
                  <div>
                    <div className="alert alert-primary mt-3 shadow-none">
                      <div className="row mb-3">
                        <div className="col-8"><p className="mb-1 text-start fw-bold small text-dark">{convertSymbolToName(depositAddress?.asset)} Deposit Address</p></div>

                        <div className="col-4 text-end"> <OverlayTrigger overlay={
                          <Tooltip>
                             {copied ?"Copied":"Copy"}
                          </Tooltip>
                        }>
                          <span className="text-dark" type="button" id="button-addon2" onClick={copyaddress}><i className="mdi mdi-content-copy"></i></span>
                        </OverlayTrigger></div>
                      </div>
                      <div className="mb-0 cdwadd">
                        <span className="fw-bold">{depositAddress?.address}</span>
                      </div>
                    </div>
                    <div className="mb-2 alert alert-warning small iconalert">
                      <span className="text-start text-center">Please convert your crypto to USD within 48 hours of the time of deposit.</span>
                    </div>
                  </div>
                  :
                  ""
                }
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Depositcrypto;
