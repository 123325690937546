import React, { useEffect, useState } from "react";
import Gifloader from "../../layout/gifloader";
import { Link } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import {
  getAdminAllCustomersList,
  resetGetAllCustomerListFailure,
  resetGetAllCustomerListSuccess
} from "../../../actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { commonError, commonSuccess } from "../../../actions/customerActions";
import { formatDate } from "../../../utils/Encryption";
import ReactPaginate from "react-paginate";
import { Form, InputGroup, Modal, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';



const CustomerList = () => {
  const dispatch = useDispatch();
  let jwtToken = sessionStorage.getItem("a_at");


  const getAC = useSelector((state) => state.adminReducer.getAllCustomersList);
  const getACF = useSelector((state) => state.adminReducer.getAllCutomersListFailed);


  const [isloader, setIsLoader] = useState(true);
  const [customersList, setCustomersList] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [guidSearch, setGuidSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [sortingOption, setSortingOption] = useState("all");
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [dataModifiedForExport, setDataModifiedForExport] = useState(null);


  let currentItems = customersList;


  useEffect(() => {
    dispatch(getAdminAllCustomersList(jwtToken));
  }, []);

  // Fetch all customers list
  useEffect(() => {
    if (getAC && getAC.data) {
      setCustomersList(getAC.data.data)
      dispatch(resetGetAllCustomerListSuccess())
      setIsLoader(false);
    }
  }, [getAC]);

  // If all customers list failed
  useEffect(() => {
    if (getACF && Object.keys(getACF).length !== 0) {
      let errObj = getACF
      if (errObj && errObj.data && errObj?.data?.message) {
        dispatch(commonError(errObj?.data?.message))
        dispatch(resetGetAllCustomerListFailure())
      }
    }
  }, [getACF]);

  // Search function using guid, name and email
  useEffect(() => {
    const filteredListCopy = [...customersList].filter((item) => {
      // Converting all the characters to lowercase, so it will avoid case-sensitive issues.
      // And removing the unnecessary white space before and after in the query.
      const guidSearchValue = guidSearch.toLowerCase().trim();
      const nameSearchValue = nameSearch.toLowerCase().trim();
      const emailSearchValue = emailSearch.toLowerCase().trim();

      if (!item.guid.toLowerCase().includes(guidSearchValue)) {
        return false;
      }
      if (!item.fullName.toLowerCase().includes(nameSearchValue)) {
        return false;
      }
      if (!item.email.toLowerCase().includes(emailSearchValue)) {
        return false;
      }
      if (sortingOption === "verified" && (item.state === "unverified" || item.state === "rejected")) {
        return false;
      }
      if (sortingOption === "unverified" && (item.state === "verified" || item.state === "rejected")) {
        return false;
      }
      if (sortingOption === "rejected" && (item.state === "verified" || item.state === 'unverified')) {
        return false;
      }
      return true;
    });

    setFilteredList(filteredListCopy);
    setPageNum(0);
  }, [guidSearch, nameSearch, emailSearch, customersList, sortingOption]);

    // Deep copy function
    const deepCopy = (obj) => {
      if (typeof obj !== 'object' || obj === null) {
        return obj;
      }
      if (Array.isArray(obj)) {
        return obj.map(deepCopy);
      }
      const copiedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          copiedObj[key] = deepCopy(obj[key]);
        }
      }
      return copiedObj;
    };
  
    // Handle convert customer list data to csv file
    const handleExportToCSV = async () => {
      let dataToExport;
      if (filteredList?.length > 0) {
        dataToExport = deepCopy(filteredList);
        console.log(dataToExport, 'data from filter')
      } else {
        dataToExport = deepCopy(customersList);
        console.log(dataToExport, 'data from customer')
      }
      // Modify the copy of dataToExport
      const exportData = dataToExport.map((customer) => {
        delete customer.guid;
        console.log(customer, 'customer_data')
        return customer;
      });
      console.log(exportData, 'export data')
      setDataModifiedForExport(exportData);
      console.log(dataModifiedForExport, 'Data modified for export')
      setShowModal(false);
      dispatch(commonSuccess("File downloaded"));
    };
  
  
    // set headers to csv file
    const headers = [
      { label: "Full Name", key: "fullName" },
      { label: "Email", key: "email" },
      { label: "Mobile Number", key: "mobileNumber" },
      { label: "Type", key: "type" },
      { label: "State", key: "state" },
    ];
  
    // Handle convert customerList data to xlsx file
    const handleExportXLSX = () => {
      let dataToExport;
      if (filteredList?.length > 0) {
        dataToExport = deepCopy(filteredList);
      } else {
        dataToExport = deepCopy(customersList);
      }
      const exportData = dataToExport.map((customer) => {
        delete customer?.guid
        return {
          fullName: customer?.fullName,
          email: customer?.email,
          mobileNumber: customer?.mobileNumber,
          type: customer?.type,
          state: customer?.state,
        };
      });
      try {
        const ws = XLSX.utils.json_to_sheet(exportData);
        // Customize xlsx header name
        ws["A1"].v = "Full Name";
        ws["B1"].v = "Email";
        ws["C1"].v = "Mobile Number";
        ws["D1"].v = "Type";
        ws["E1"].v = "State";
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "customers_list.xlsx");
        setShowModal(false);
        dispatch(commonSuccess("File downloaded"));
      } catch (error) {
        setShowModal(false);
        console.error("XLSX Conversion Error:", error);
        dispatch(commonError("Unable to export XLSX file"));
      }
    };
  
    // file export show modal 
    const updateShowModal = () => {
      if ((guidSearch?.length > 0 || sortingOption?.length > 0) && filteredList?.length > 0) {
        setShowModal(true)
      } else if (guidSearch?.length === 0 && sortingOption?.length > 0 && customersList?.length > 0) {
        setShowModal(true)
      } else {
        setShowModal(false)
      }
    }
  
  // Table datas
  const Items = ({ currentItems }) => {
    const filteredItem = currentItems.filter((item) => {
      // Converting all the characters to lowercase, so it will avoid case-sensitive issues.
      // And removing the unnecessary white space before and after in the query.
      const guidSearchValue = guidSearch.toLowerCase().trim();
      const nameSearchValue = nameSearch.toLowerCase().trim();
      const emailSearchValue = emailSearch.toLowerCase().trim();

      if (!item.guid.toLowerCase().includes(guidSearchValue)) {
        return false;
      }
      if (!item.fullName.toLowerCase().includes(nameSearchValue)) {
        return false;
      }
      if (!item.email.toLowerCase().includes(emailSearchValue)) {
        return false;
      }
      if (sortingOption === "verified" && (item.state === "unverified" || item.state === "rejected")) {
        return false;
      }
      if (sortingOption === "unverified" && (item.state === "verified" || item.state === "rejected")) {
        return false;
      }
      if (sortingOption === "rejected" && (item.state === "verified" || item.state === 'unverified')) {
        return false;
      }
      return true;
    })


    const setClassName = (state) => {
      if(state === "verified") {
        return "mw-badge badge badge-success";
      }else if(state === "unverified" || state === "rejected") {
        return "mw-badge badge badge-danger1"
      }else {
        return "mw-badge badge badge-warning"
      }
    }

    return (
      <>
        {
          filteredItem?.length > 0 ? (
            <Table hover responsive>
              <thead>
                <tr>
                  <th className="">GUID</th>
                  <th className="">Full Name</th>
                  <th className="">Email</th>
                  <th className=" text-end">Mobile Number</th>
                  <th className="text-center">Type</th>
                  <th className="text-center">State</th>
                  <th className="text-end">Created At</th>
                </tr>
              </thead>

              <tbody>
                {filteredItem &&
                  filteredItem?.map((item, index) => (
                    <tr key={item?.guid}>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        <Link
                          to={`/admin/customer/${item?.guid}`}
                        >
                          <span className="text-primary fw-bold">
                            {item?.guid}
                          </span>
                        </Link>
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }} className="text-capitalize">{item.fullName}</td>
                      <td style={{ whiteSpace: 'nowrap' }}>{item.email}</td>
                      <td style={{ whiteSpace: 'nowrap' }} className="text-end">{item.mobileNumber}</td>
                      <td className="text-center text-capitalize">{item.type}</td>
                      <td style={{ whiteSpace: 'nowrap' }} className="text-center"> <span className={setClassName(item.state)} >{item.state}</span></td>
                      <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDate(item.Swagger_created_at)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center mt-5">
              <p>No customers found.</p>
            </div>
          )
        }

{
          showModal && (
            <Modal centered show={showModal} className="tce-popup w250px fadeInDown">
              <div className="tce-ppcon ">
                <div className="tce-ppcls" onClick={() => setShowModal(false)}>
                  <span>x</span>
                </div>

                <div className="getverified fadeInDown">
                  <Modal.Title id="contained-modal-title-vcenter">
                    Download Options
                  </Modal.Title>

                  <Modal.Body className="p-3 p-md-4">
                    <div className="d-flex flex-row justify-content-center gap-2 gap-md-3">
                      <CSVLink data={dataModifiedForExport || []} headers={headers} className="btn btn-primary" onClick={() => handleExportToCSV()}>
                        <i className="mdi mdi-download fs-4 pe-2"></i>  CSV
                      </CSVLink>
                      <button className="btn btn-primary" onClick={() => handleExportXLSX()}><i className="mdi mdi-download fs-4 pe-2"></i>  XLSX</button>
                    </div>
                    <div className="text-center mt-3">
                      <small><strong>Note:</strong> Click the button above to initiate the export</small>
                    </div>
                  </Modal.Body>
                </div>
              </div>
            </Modal>
          )
        }
      </>
    );
  }


  // Pagination calculations
  const itemsPerPage = 20;
  const pageCount = Math.max(1, Math.ceil(filteredList.length / itemsPerPage)); // Ensure pageCount is at least 1
  const endOffset = pageNum + itemsPerPage;
  currentItems = filteredList.slice(pageNum, endOffset);

  // Changing the page number
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % filteredList.length;
    setPageNum(newOffset);
  };


  return (
    <>
      <AdminHeader />
      <AdminSidebar />

      <div className="content-body dash-main ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Customers List</h4>
                </div>

                <div className={`row align-items-center filterset mx-0 ${isloader ? "d-none" : "d-flex"}`}>
                  <div className="col-lg-3 col-md-6 col-12 my-3">
                    <Form className="w-100">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Search Guid..."
                          value={guidSearch}
                          onChange={(e) => setGuidSearch(e.target.value)}
                        />
                      </InputGroup>
                    </Form>
                  </div>

                  <div className="col-lg-3 col-md-6 col-12 my-3">
                    <Form className="w-100">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Search Name..."
                          value={nameSearch}
                          onChange={(e) => setNameSearch(e.target.value)}
                        />
                      </InputGroup>
                    </Form>
                  </div>

                  <div className="col-lg-3 col-md-6 col-12 my-3">
                    <Form className="w-100">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Search Email..."
                          value={emailSearch}
                          onChange={(e) => setEmailSearch(e.target.value)}
                        />
                      </InputGroup>
                    </Form>
                  </div>

                  <div className="col-lg-3 col-md-6 col-12 my-3">
                    <select
                      style={{ padding: "9.5px", cursor: "pointer" }}
                      id="sortingDropdown"
                      className="form-select"
                      value={sortingOption}
                      onChange={(e) => setSortingOption(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="verified">Verified</option>
                      <option value="unverified">Unverified</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 col-12 my-3 text-center text-md-start">
                    <button className="btn btn-primary" onClick={() => handleExportXLSX()}><i className="mdi mdi-download fs-4 pe-2"></i>  XLSX</button>
                  </div>
                </div>

                <div className="card-body py-0">
                  <div
                    className={`${isloader ? "d-block" : "d-none"
                      } justify-content-center d-flex align-item-center my-5 py-5`}
                  >
                    <Gifloader />
                  </div>

                  {customersList && customersList.length > 0 ? (
                    <>
                      <Items currentItems={currentItems} />
                      <div className={(isloader || currentItems?.length === 0) ? "d-none" : ""}>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={"Next"}
                          pageRangeDisplayed={20}
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          previousLabel={"Previous"}
                          containerClassName={'pagination justify-content-center my-3'}
                          pageClassName={'page-item'}
                          pageLinkClassName={'page-link'}
                          activeClassName={'active'}
                          previousClassName={'page-item'}
                          previousLinkClassName={'page-link'}
                          nextClassName={'page-item'}
                          nextLinkClassName={'page-link'}
                          forcePage={pageNum === 0 ? 0 : currentPage}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={`${isloader ? "d-none" : "d-block"} text-center mt-5`}>
                      <p>No customers found.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerList;
